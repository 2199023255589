import { useEffect } from 'react';
import useTemplateStyles from './useTemplateStyles';
import { useSelector } from 'react-redux';

const DynamicStyles = () => {
  const { logo, colors, typography, buttons } = useTemplateStyles();
  const { businessName } = useSelector((store) => store.configs);

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty('--primary', colors.primary);
    root.style.setProperty('--secondary', colors.secondary);
    root.style.setProperty('--tertiary', colors.tertiary);
    root.style.setProperty('--quaternary', colors.quaternary);
    root.style.setProperty('--headings', colors.headings);
    root.style.setProperty('--body', colors.body);

    root.style.setProperty('--heading-font', typography.headings || 'Poppins, sans-serif');
    root.style.setProperty('--body-font', typography.body || 'Poppins, sans-serif');

    root.style.setProperty('--button-primary', buttons.primary);
    root.style.setProperty('--button-hover', buttons.hover);
    root.style.setProperty('--button-border-radius', `${buttons.borderRadius}px`);

  }, [colors, typography, buttons]);

  useEffect(() => {
    document.title = businessName || 'Zed eCommerce';
    const faviconElement = document.querySelector("link[rel='icon']");

    if (logo?.logoType === 'logo') {
      if (logo?.faviconPath) {
        faviconElement.href = logo.faviconPath;
      }
    } else {
      const svg = `
        <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
          <rect width="100" height="100" fill="#fff"/>
          <text x="50%" y="50%" font-size="120" text-anchor="middle" stroke="${colors.primary}" stroke-width="1px" dy=".3em" fill="${colors.body}">
            ${logo?.logoText?.charAt(0) || 'Z'}
          </text>
        </svg>
      `;
      const svgBlob = new Blob([svg], { type: 'image/svg+xml' });
      const url = URL.createObjectURL(svgBlob);
      faviconElement.href = url;
    }
  }, [businessName, logo, colors]);

  return null;
};

export default DynamicStyles;
