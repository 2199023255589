import { ArrowBackIos, Visibility } from "@mui/icons-material";
import { Box, Button, CircularProgress, Divider, Grid, Tab, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HttpRequest } from "../HttpRequest";
import imagePlaceHolder from './../../images/img-placeholder.svg';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CustomTable from "../common/CustomTable";
import CurrencyFormatter from "../common/currencyFormatter";

const AntTabs = styled(TabList)({ borderBottom: "none", "& .MuiTabs-indicator": { backgroundColor: "#fff" } });
const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none", maxWidth: 180, [theme.breakpoints.up("sm")]: { minWidth: 0 }, textWrap: 'nowrap', padding: '13px 14px 12px',
    fontWeight: theme.typography.fontWeightRegular, marginRight: theme.spacing(1), width: '100%',
    fontSize: "16px", background: '#f5f6f7', borderRadius: '5px', fontStretch: "normal", fontStyle: "normal",
    letterSpacing: "normal", textAlign: "left", color: "#6e7074", fontFamily: ["Poppins"].join(","),
    "&:hover": { color: "#032541", opacity: 1 }, "&.Mui-selected": { color: "#032541", backgroundColor: '#eaf4fc', borderBottom: '', fontWeight: 600 },
    "&.Mui-focusVisible": { backgroundColor: "none" },
}));


const CustomerAllOrders = () => {
    const navigate = useNavigate();
    const [customer, setCustomer] = useState([]);
    const { customerId } = useParams();
    const [tabValue, setTabValue] = useState('all')
    const [pageState, setPageState] = useState({
        isLoading: true,
        total: 0,
        page: 1,
        pageSize: 1000,
        data: []
    });

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const formatDate = (value) => {
        const dateString = value;
        const date = new Date(dateString);
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = months[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();
        return `${month} ${day}, ${year}`;
    };
    const columns = [
        {
            field: 'image', headerName: 'Image',
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Image  </Box>) },
            renderCell: (params) => {
                return (
                    <Box onClick={(event)=>handleRowClick(event,params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
                        <img src={imagePlaceHolder} style={{ width: '34.2px', height: '38.9px'}} />
                    </Box>)
            }
        },
        {
            field: 'orderNumber', headerName: 'order Number', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Order Number  </Box>) },
            renderCell: (params) => {
                return (
                    <Box onClick={(event)=>handleRowClick(event,params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "normal",textDecoration:'underline' }}>
                        {params?.row?.orderNumber}
                    </Box>)
            }
        },
        {
            field: 'itemsCount', headerName: 'Items', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Items  </Box>) },
            renderCell: (params) => { return (<Box onClick={(event)=>handleRowClick(event,params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>    {params?.row?.items?.length} Items  </Box>) }
        },
        {
            field: 'amount', headerName: 'Amount', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}>Total Amount  </Box>) },
            renderCell: (params) => {
                return (<Box onClick={(event)=>handleRowClick(event,params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
                    {CurrencyFormatter(params.row.amount, 'KES')}
                </Box>)
            }
        },
        {
            field: 'status', headerName: 'Order Status', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Order Status  </Box>) },
            renderCell: (params) => {
                return (
                    <Box onClick={(event)=>handleRowClick(event,params)} style={{ cursor: 'pointer', color: getOrderStatusColor(params.row?.orderStatus), fontSize: "16px", fontWeight: "600", textAlign: 'left' }}>
                        {params.row?.orderStatus ?? 'Pending'}
                    </Box>
                )
            }
        }
    ]
    function getStatusColor(params) {
        const statusColors = {
            'paid': '#17ae7b',
            'unpaid': '#dc3545',
        };
        return statusColors[params.row.status] || '#dc3545';
    }
    function getOrderStatusColor(params) {
        const statusColors = {
            'Received': '#707070',
            'Delivered': '#17ae7b',
            'Shipped': '#000000',
            'Processing': '#f79009'
        };
        return statusColors[params.row?.orderStatus] || '#dc3545';
    }
    const getCustomerDetails = () => {
        HttpRequest({
            method: 'GET',
            url: `/api/get_customer_by_customer_id?customerId=${customerId}`
        }).then((data) => {
            setCustomer(data.response.data);
        }).catch((error) => {
        });
    }
    const getCustomerOrders = () => {
        setPageState({
            ...pageState,
            data: [],
            total: 0,
            isLoading: true
        })
        HttpRequest({
            method: 'GET',
            url: `/api/v1/ecommerce/getzedecommercecustomerorder?customerId=${customerId}`
        }).then((data) => {
            let ordersData = data.response.data;
            if (tabValue === 'Current') {
                ordersData = ordersData.filter(order => order?.orderStatus !== "Delivered");
            }
            if (tabValue === 'Completed') {
                ordersData = ordersData.filter(order => order?.orderStatus === "Delivered");
            }
            setPageState({
                ...pageState,
                data: ordersData,
                total: data?.response?.data?.total,
                isLoading: false
            })

        }).catch((error) => {
            
        });
    }
    useEffect(() => {
        getCustomerDetails();
    }, [])
    useEffect(() => {
        getCustomerOrders();
    }, [tabValue])
    const CustomerComponent = ({ createdAt }) => {
        const createdDate = new Date(createdAt);
        const timeDiff = new Date() - createdDate;
        const years = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365));
        if (years < 1) {
            const months = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 30));
            if (months < 1) {
                const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
                return (
                    <Box sx={{ color: "#707070", fontSize: "14px", fontStyle: "normal", fontWeight: "normal" }}>
                        Customer for {days} {days === 1 ? 'day' : 'days'}
                    </Box>
                );
            } else {
                return (
                    <Box sx={{ color: "#707070", fontSize: "14px", fontStyle: "normal", fontWeight: "normal" }}>
                        Customer for {months} {months === 1 ? 'month' : 'months'}
                    </Box>
                );
            }
        } else {
            return (
                <Box sx={{ color: "#707070", fontSize: "14px", fontStyle: "normal", fontWeight: "normal" }}>
                    Customer for {years} {years === 1 ? 'year' : 'years'}
                </Box>
            );
        }
    }
    function getOrderStatusColor(status) {
        const statusColors = {
            'Received': '#707070',
            'Delivered': '#17ae7b',
            'Shipped': '#000000',
            'Processing': '#f79009'
        };
        return statusColors[status] || '#dc3545';
    }
    const handleRowClick = (event, params) => {
        event.stopPropagation();
        navigate(`/zed_ecommerce/orders/${params?.row?._id}/details`)
      }
    return (
        <div style={{ marginTop: '4%' }}>

            {customer && <Box sx={{ mb: '2%' }}>
                <Box sx={{ display: 'flex' }}>
                    <ArrowBackIos sx={{ width: '30px', height: '50px' }} onClick={() => navigate(-1)} />
                    <Typography variant="h4" sx={{ color: "#032541", fontSize: "28px", fontStyle: "normal", fontWeight: "600", margin: '7px 15px 4px 0' }}>
                        View Orders
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Typography variant='h4' sx={{ color: "#707070", fontSize: "14px", fontStyle: "normal", fontWeight: "normal", mr: '5px' }}>{customer?.customerpreference?.city}, Kenya. </Typography>
                    <CustomerComponent createdAt={customer?.createdAt} />
                </Box>
            </Box>}
            <Box>
                <TabContext value={tabValue}>
                    <AntTabs value={tabValue} onChange={handleChange} sx={{ display: 'flex' }}>
                        <AntTab label="All Orders" value="all" />
                        <AntTab label="Current Orders" value="Current" style={{ marginLeft: '20px', padding: '13px 14px 12px' }} />
                        <AntTab label="Completed Orders" value="Completed" style={{ marginLeft: "20px", padding: '13px 14px 12px' }} />
                    </AntTabs>
                    <TabPanel value="all" style={{ marginLeft: '-30px' }}>
                        <Grid item>
                            <CustomTable columns={columns} data={pageState.data} loading={pageState?.isLoading} />
                        </Grid>
                    </TabPanel>
                    <TabPanel value="Current" style={{ marginLeft: '-30px' }}>
                        <Grid item>
                            <CustomTable columns={columns} data={pageState.data} loading={pageState?.isLoading} />
                        </Grid>
                    </TabPanel>
                    <TabPanel value="Completed" style={{ marginLeft: "-30px" }}>
                        <Grid item>
                            <CustomTable columns={columns} data={pageState.data} loading={pageState?.isLoading} />
                        </Grid>
                    </TabPanel>
                </TabContext>
            </Box>
        </div>
    )
}
export default CustomerAllOrders;