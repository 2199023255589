import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Box, Grid, CircularProgress } from '@mui/material';
import { HttpRequest } from '../HttpRequest';
import secureLocalStorage from 'react-secure-storage';
import Footer from '../customer/footer';
import { useDispatch } from 'react-redux';
import { setBanners, setButtons, setColors, setDisclaimer, setLogo, setPreOrderBadgeType, setSocialMedia, setTemplate, setTopBar, setTypography } from '../../features/template';
import useGetTemplates from '../common/templates';
import { setBusinessAddress, setBusinessEmail, setBusinessName, setBusinessPhone } from '../../features/configsSlice';
import Header from '../customer/header';

const BusinessRoutes = ({ isNotAuthRoute }) => {
  const [loading, setLoading] = useState(true);
  const [tokenGenerated, setTokenGenerated] = useState(false);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const templates = useGetTemplates();

  const updateTemplate = (selectedTemplate) => {
    dispatch(setTemplate(selectedTemplate?.template));
    dispatch(setLogo(selectedTemplate?.logo));
    dispatch(setColors(selectedTemplate?.colors));
    dispatch(setTypography(selectedTemplate?.typography));
    dispatch(setButtons(selectedTemplate?.buttons));
    dispatch(setBanners(selectedTemplate?.banners));
    dispatch(setPreOrderBadgeType(selectedTemplate?.preOrderBadgeType));
    dispatch(setDisclaimer(selectedTemplate?.disclaimer));
    dispatch(setTopBar(selectedTemplate?.topBar));
    dispatch(setSocialMedia(selectedTemplate?.socialMedia));
  };

  const getBusinessToken = async () => {
    const currentDomain = window.location.origin;
    HttpRequest({
      method: 'GET',
      url: `/api/v1/ecommerce/getzedecommerce-domain-details?subdomain=${currentDomain}`,
    })
      .then(async (data) => {
        if (data.status === 200) {
          const businessDetails = data?.response;
          const businessNumber = secureLocalStorage.getItem('businessId');
          if (!businessNumber) {
            secureLocalStorage.setItem('businessId', businessDetails?.businessNumber);
         // secureLocalStorage.setItem('businessId',9001797);
          } else {
            setTokenGenerated(true);
          }
          dispatch(setBusinessName(businessDetails?.businessName));
          dispatch(setBusinessEmail(businessDetails?.businessOwnerEmail));
          dispatch(setBusinessPhone(businessDetails?.businessOwnerPhone));
          dispatch(setBusinessAddress(businessDetails?.businessOwnerAddress));

          let templateData = data?.response?.ecommerceTemplateConfig;
          updateTemplate(templateData || templates['Zedify']);
        }
      })
      .catch((error) => {
      })
      .finally(() => {
        setLoading(false);
      });

  };

  useEffect(() => {
    if (params.has('action') && params.has('template')) {
      const selectedTemplate = params.get('template');
      updateTemplate(templates[selectedTemplate]);
      setLoading(false);
    } else {
      getBusinessToken();
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : (
        <>
          {isNotAuthRoute && (
            <Header />
          )}
          <Grid item sx={{ flexGrow: 1, marginTop: isNotAuthRoute ? { xs: '180px', md: '250px' } : '0' }}>
            <Box className="container">
              <Outlet />
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ mt: 'auto', width: '100%', pt: '50px' }}>
              <Footer />
            </Box>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default BusinessRoutes;
