import { Box, Typography, Button, CircularProgress, TextField, Alert, Checkbox, RadioGroup, Radio, FormControl, FormControlLabel, Grid, Divider, IconButton, Tooltip, FormHelperText } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { HttpRequest } from '../../HttpRequest';
import { useNavigate, useParams } from 'react-router-dom';
import { AddCircle, CheckBox, KeyboardArrowDown, KeyboardArrowUp, RemoveCircle, ShoppingCart } from '@mui/icons-material';
import { addToCart, setPackage } from '../../../features/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { SuccessAlert } from '../../common/snackbar/success';
import { ErrorAlert } from '../../common/snackbar/error';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import useBestPackage from '../../common/packages';
import secureLocalStorage from 'react-secure-storage';
import noImagePlaceholder from '../../../images/No-Image-Placeholder.png'
import PreorderTypes from '../../common/PreorderTypes';


const businessNumber = secureLocalStorage.getItem("businessId");
const customerId = secureLocalStorage.getItem('customerId');

const ProductPage = () => {
  const stateCart = useSelector((state) => state.cart);
  let cart = stateCart.cart;

  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState({});
  const { productId } = useParams();
  const [count, setCount] = useState(0);
  const [variations, setVariations] = useState({});
  const [variationKey, setVariationKey] = useState('');
  const [variationId, setVariationId] = useState('');
  const dispatch = useDispatch();
  const [price, setPrice] = useState(0);
  const [normalPrice, setNormalPrice] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [successShow, setSuccessShow] = useState({ state: false, message: "" })
  const [errorShow, setErrorShow] = useState({ state: false, message: "" })
  const [stock, setStock] = useState(0);
  const [selectedAdditionalServices, setSelectedAdditionalServices] = useState([]);
  const [additionalServicesAmount, setAdditionalServicesAmount] = useState(0);
  const [checkedItems, setCheckedItems] = useState({});
  const [customValues, setCustomValues] = useState({});
  const [customError, setCustomError] = useState(false);
  const [activeImage, setActiveImage] = useState('');
  const [variationKeyError, setVariationKeyError] = useState(false);
  const navigate = useNavigate();
  const { fetchAndDetermineBestPackage } = useBestPackage();
  const [paymentMode, setPaymentMode] = useState('preOrderPayMethod');
  const [isPreOrderEnabled, setIsPreOrderEnabled] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const preOrderTypes = PreorderTypes();
  const template = useSelector((state) => state.template);
  const [variationsError, setVariationsError] = useState(false);

  const primaryColor = 'var(--primary)';
  const bodyColor = 'var(--body)';
  const headingsColor = 'var(--headings)';

  const handleIncrementCart = () => {

    const alreadySelectedVariations = new Set(variationKey.split(',').map(item => item.trim()));
    const matchedPricing = product?.pricing?.find((pricing) => {
      const pricingVariations = new Set(pricing?.variationKey?.split(',').map(item => item.trim()));
      return alreadySelectedVariations.size === pricingVariations.size && [...alreadySelectedVariations].every(variation => pricingVariations.has(variation));
    });

    if (!matchedPricing) {
      setVariationsError(true);
      window.scrollTo(0, 0);
      setErrorShow({ state: true, message: "Please select all available variations." });
      return;
    }

    if (stock < count + 1) {
      setErrorShow({ state: true, message: "The selected quantity exceeds available stock." });
      return;
    }

    if (variationKey.trim() === '') {
      setVariationKeyError(true);
      setErrorShow({ state: true, message: "Please select variations." });
      return;
    }

    if (stock > 0) {
      setCount((prevCount) => prevCount + 1);
    } else {
      setErrorShow({ state: true, message: "Product currently out of stock." });
    }
  };
  const handleCartInput = (value) => {

    const alreadySelectedVariations = new Set(variationKey.split(',').map(item => item.trim()));
    const matchedPricing = product?.pricing?.find((pricing) => {
      const pricingVariations = new Set(pricing?.variationKey?.split(',').map(item => item.trim()));
      return alreadySelectedVariations.size === pricingVariations.size && [...alreadySelectedVariations].every(variation => pricingVariations.has(variation));
    });

    if (!matchedPricing) {
      setVariationsError(true);
      window.scrollTo(0, 0);
      setErrorShow({ state: true, message: "Please select all available variations." });
      return;
    }

    if (stock < value) {
      setErrorShow({ state: true, message: "The selected quantity exceeds available stock." });
      setCount(stock);

      return;
    }

    if (variationKey.trim() === '') {
      setVariationKeyError(true);
      setErrorShow({ state: true, message: "Please select variations." });
      return;
    }
    if (value < 0) {
      setErrorShow({ state: true, message: "Quantity must be greater than 0." });
      return;
    }
    if (stock > 0) {
      setCount(value);
    } else {
      setErrorShow({ state: true, message: "Product currently out of stock." });
    }
  };

  const handleDecrementCart = () => {
    if (count > 0) {
      setCount((prevCount) => prevCount - 1);
    }
  };

  const handleAddToCart = async () => {
    if (stock < count) {
      setErrorShow({ state: true, message: "The selected quantity exceeds available stock." });
      return;
    }
    setCustomError(false);
    const hasEmptyCustomValues = selectedAdditionalServices.some(service => {
      return service.items.some(item => {
        return item?.isCustomizableValue?.trim() === '';
      });
    });
    if (hasEmptyCustomValues) {
      setCustomError(true);
      setErrorShow({ state: true, message: "Please enter customization values." });
      return;
    }

    const isAnyEmpty = selectedAdditionalServices?.items?.some(service =>
      service.isCustomizableValue.trim() === ''
    );

    if (isAnyEmpty) {
      setErrorShow({ state: true, message: "Please fill all fields" });
      return;
    }
    const payload = {
      id: product._id,
      image: product.featuredImage,
      name: product.productName,
      price: price,
      variations: variations,
      quantity: count,
      variationId: variationId,
      additionalServices: selectedAdditionalServices,
      customizationAmount: additionalServicesAmount,
      isPreOrder: checkPreorder(product?.pricing),
      remainingStock: stock
    };
    const assignPackage = async (quantity = 1) => {
      let selectedPackage = await fetchAndDetermineBestPackage(quantity);

      if (selectedPackage !== null) {
        let packageQuantity = selectedPackage?.availableStock;

        if (packageQuantity <= 0) {
          setErrorShow({ state: true, message: "An error occurred while selecting a packaging." });
          return false;
        }

        const packagePayload = {
          id: selectedPackage._id,
          image: selectedPackage.featuredImage,
          name: selectedPackage.productName,
          price: selectedPackage.pricing[0]?.discountedPrice,
          quantity: 1,
          variationId: selectedPackage.pricing[0]?._id,
          availableStock: selectedPackage?.pricing[0]?.availableStock
        };

        dispatch(setPackage(packagePayload));
        return true;
      } else {
        setErrorShow({ state: true, message: "No suitable package found." });
        return false;
      }
    };
    const packageAssigned = await assignPackage(count);
    if (packageAssigned) {
      dispatch(addToCart(payload));
      postCartItem(payload);
      setSuccessShow({ state: true, message: "Product added to cart." });
    }
  }
  const postCartItem = async (payload) => {
    try {
      const data = await HttpRequest({
        method: 'POST',
        url: `/api/v1/ecommerce/savecartitem?businessNumber=${businessNumber}`,
        body: {
          "cartItem": {
            ...payload,
            "businessNo": businessNumber,
            "userId": customerId,
          }
        }
      });
    } catch (error) { }
  }
  const handleVariations = (type, variation) => {
    setVariationsError(false);
    setVariations(prevVariations => {
      const updatedVariations = { ...prevVariations, [type]: variation };
      const variationsArray = Object.values(updatedVariations);
      const generatedVariationKey = variationsArray.join(',');
      setVariationKey(generatedVariationKey);
      getPricingForVariationKey(generatedVariationKey, product?.pricing);
      return updatedVariations;
    });
  };

  const isOutOfStock = (currentVariations, type, variationValue) => {
    const updatedVariations = { ...currentVariations, [type]: variationValue };
    const variationsArray = Object.values(updatedVariations);
    const generatedVariationKey = variationsArray.join(',');
    const matchedPricing = product.pricing.find(pricing => {
      const pricingVariations = new Set(pricing.variationKey.split(',').map(item => item.trim()));
      return variationsArray.length === pricingVariations.size && variationsArray.every(variation => pricingVariations.has(variation));
    });
    return matchedPricing ? matchedPricing.availableStock <= 0 && !isPreOrderEnabled : false;
  };

  const getPricingForVariationKey = (variationKey, pricingData) => {
    const variations = new Set(variationKey?.split(',').map(item => item.trim()));
    const matchedPricing = pricingData?.find((pricing) => {
      const pricingVariations = new Set(pricing?.variationKey?.split(',').map(item => item.trim()));
      return variations.size === pricingVariations.size && [...variations].every(variation => pricingVariations.has(variation));
    });
    setVariationId(matchedPricing?._id);
    setPrice(matchedPricing ? matchedPricing.discountedPrice + additionalServicesAmount : price);
    setNormalPrice(matchedPricing ? matchedPricing?.normalPrice + additionalServicesAmount : normalPrice);
    setDiscountedPrice(matchedPricing ? matchedPricing?.discountedPrice + additionalServicesAmount : discountedPrice);
    setStock(matchedPricing ? matchedPricing?.availableStock : 0);
    if (matchedPricing) {

      const preOrderdateEnd = matchedPricing?.preOrderdateEnd;
      const currentDate = new Date();
      const preOrderEndDate = new Date(preOrderdateEnd);

      const isPreOrderActive = currentDate <= preOrderEndDate;

      if (matchedPricing?.isPreOrderEnabled && matchedPricing?.preOrderStockLeft > 0 && isPreOrderActive && matchedPricing?.availableStock <= 0) {
        setPaymentMode(matchedPricing?.preOrderPayMethod);
        setIsPreOrderEnabled(matchedPricing?.isPreOrderEnabled);
        setStock(matchedPricing ? matchedPricing?.preOrderStockLeft : 0);
        setEndDate(matchedPricing?.preOrderdateEnd);
      } else {
        setIsPreOrderEnabled(false);
      }
    }
  };

  const getProduct = () => {
    setLoading(true);
    const businessNumber = secureLocalStorage.getItem("businessId");
    HttpRequest({
      method: 'GET',
      url: `/api/v1/ecommerce/get_product/${productId}?businessNumber=${businessNumber}`
    }).then(async (data) => {
      let productDetails = data?.response?.data;

      setProduct(productDetails);
      setPrice(productDetails?.pricing && productDetails?.pricing?.length > 0 ? productDetails?.pricing[0]?.discountedPrice : productDetails?.productPrice ?? 0);
      setNormalPrice(productDetails?.pricing && productDetails?.pricing?.length > 0 ? productDetails?.pricing[0]?.normalPrice : productDetails?.productPrice ?? 0);
      setDiscountedPrice(productDetails?.pricing && productDetails?.pricing?.length > 0 ? productDetails?.pricing[0]?.discountedPrice : productDetails?.productPrice ?? 0)
      setStock(productDetails?.pricing && productDetails?.pricing?.length > 0 ? productDetails?.pricing[0]?.availableStock : 0);
      setActiveImage(productDetails?.featuredImage ?? noImagePlaceholder);

      if (productDetails?.variations && productDetails?.variations[0]?.variationName === 'default') {
        setVariationKey(productDetails?.variations[0]?.variationName);
        setVariations({ ...variations, [productDetails?.variations[0]?.variationName]: variations[0]?.variationValues });
        setVariationId(productDetails?.pricing[0]?._id);
        setStock(productDetails?.pricing && productDetails.pricing.length > 0 ? productDetails.pricing[0].availableStock : 0);
      } else {
        const availablePricing = productDetails?.pricing?.find(price => price.availableStock > 1);
        if (availablePricing) {
          setVariationKey(availablePricing.variationKey);
          setVariations({
            ...variations,
            [productDetails?.variations[0]?.variationName]: variations[0]?.variationValues
          });
          setVariationId(availablePricing._id);
          setStock(availablePricing.availableStock);
        }

      }
      setIsPreOrderEnabled(checkPreorder(productDetails?.pricing));
      setEndDate(productDetails?.pricing[0]?.preOrderdateEnd);

    }).catch((error) => {
    }).finally(() => {
      setLoading(false);
    });
  };
  const checkVariationSelected = (variation) => {
    if (variationKey === null || variationKey === undefined) {
      return false;
    }
    const variationArray = variationKey?.split(',').map(value => value.trim());
    const variationSelected = variationArray.includes(variation);
    if (variationSelected) {
      return true;
    } else {
      return false;
    }
  }
  function toSentenceCase(text) {
    return text.toLowerCase().split('. ').map(sentence => {
      return sentence.charAt(0).toUpperCase() + sentence.slice(1);
    }).join('. ');
  }
  const handleSelectAdditionalServicesMultiple = (service, item, isChecked) => {
    setSelectedAdditionalServices(prevState => {
      const serviceIndex = prevState.findIndex(prevService => prevService.serviceId === service.serviceId);
      if (isChecked) {
        if (serviceIndex > -1) {
          const updatedServices = [...prevState];
          updatedServices[serviceIndex] = {
            ...updatedServices[serviceIndex],
            items: [
              ...updatedServices[serviceIndex].items,
              { itemId: item._id, itemName: item.itemName, itemPrice: item.itemPrice }
            ]
          };
          return updatedServices;
        } else {
          return [
            ...prevState,
            {
              serviceId: service.serviceId,
              items: [{ itemId: item._id, itemName: item.itemName, itemPrice: item.itemPrice }]
            }
          ];
        }
      } else {
        if (serviceIndex > -1) {
          const updatedServices = [...prevState];
          updatedServices[serviceIndex] = {
            ...updatedServices[serviceIndex],
            items: updatedServices[serviceIndex].items.filter(serviceItem => serviceItem.itemId !== item._id)
          };
          if (updatedServices[serviceIndex].items.length === 0) {
            updatedServices.splice(serviceIndex, 1);
          }
          return updatedServices;
        }
      }
      return prevState;
    });
  };

  const handleSelectAdditionalServicesSingle = (service, item) => {
    setSelectedAdditionalServices(prevState => {
      const updatedServices = prevState.map(prevService => {
        if (prevService.serviceId === service.serviceId) {
          return {
            ...prevService,
            items: [{ itemId: item._id, itemName: item.itemName, itemPrice: item.itemPrice }]
          };
        }
        return prevService;
      });

      if (!updatedServices.some(prevService => prevService.serviceId === service.serviceId)) {
        updatedServices.push({
          serviceId: service.serviceId,
          items: [{ itemId: item._id, itemName: item.itemName, itemPrice: item.itemPrice }]
        });
      }
      return updatedServices;
    });
  };

  const calculateAdditionServiceAmount = () => {

    const totalPriceSum = selectedAdditionalServices.reduce((total, service) => {
      return total + service.items.reduce((subtotal, item) => subtotal + item.itemPrice, 0);
    }, 0);
    return totalPriceSum;
  }

  const handleCustomizeClick = (serviceId, itemId, itemName) => {
    setCustomError(false);
    const inputValue = document.getElementById(`custom-value-${itemId}`).value;
    setCustomValues(prevState => ({
      ...prevState,
      [itemId]: inputValue
    }));

    setSelectedAdditionalServices(prevState => {
      return prevState.map(service => {
        if (service.serviceId === serviceId) {
          return {
            ...service,
            items: service.items.map(item => {
              if (item.itemId === itemId) {
                return {
                  ...item,
                  isCustomizableValue: inputValue
                };
              }
              return item;
            })
          };
        }
        return service;
      });
    });
  };

  const [showAllServices, setShowAllServices] = useState({});

  const handleToggleShowAllItems = (serviceId) => {
    setShowAllServices((prev) => ({
      ...prev,
      [serviceId]: !prev[serviceId],
    }));
  };

  const checkPreorder = (pricingObject) => {
    const now = new Date();

    const hasValidPreOrder = pricingObject?.some(item =>
      item.isPreOrderEnabled && new Date(item.preOrderdateEnd) > now && item?.preOrderStockLeft > 0 && item?.availableStock <= 0
    );

    return hasValidPreOrder;
  };
  const isSoldOut = (pricingObject) => {
    return pricingObject?.every(item =>
      item?.availableStock < 1
    );
  };

  useEffect(() => {
    let newAmount = calculateAdditionServiceAmount();
    setAdditionalServicesAmount(newAmount);
  }, [selectedAdditionalServices]);
  useEffect(() => {
    getProduct();
  }, [productId])


  const CountdownTimer = ({ targetDate }) => {
    const calculateTimeLeft = () => {
      const difference = new Date(targetDate) - new Date();
      let timeLeft = {};

      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60)
        };
      } else {
        timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
      }

      return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);

      return () => clearInterval(timer);
    }, []);

    return (
      <Typography> {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s</Typography>
    );
  };
  const VariationButtons = ({ variant, variations, bodyColor, primaryColor, handleVariations, checkVariationSelected, toSentenceCase, isOutOfStock }) => {
    return variant.variationValues.map((variationValue, key) => {
      let buttonStyle = {};
      let buttonVariant = 'outlined';

      if (variant.variationName.toLowerCase() === 'gender') {
        buttonStyle = {
          width: '100%', maxWidth: '104.2px', padding: '13.5px 37.7px 14.5px 36.5px', backgroundColor: checkVariationSelected(variationValue) === true ? primaryColor : '#fff', "&:hover": {
            backgroundColor: checkVariationSelected(variationValue) === true ? primaryColor : '#fff',
            border: checkVariationSelected(variationValue) === true ? 'none' : `solid 1px ${bodyColor}`,
          }
        };

      } else if (variant.variationName.toLowerCase() === 'size' || variant.variationName.toLowerCase() === 'brand') {
        buttonStyle = {
          width: 'auto', padding: '13.5px 19.5px 14.5px', backgroundColor: checkVariationSelected(variationValue) === true ? primaryColor : '#fff', "&:hover": {
            backgroundColor: checkVariationSelected(variationValue) === true ? primaryColor : '#fff',
            border: checkVariationSelected(variationValue) === true ? 'none' : `solid 1px ${bodyColor}`,
          }
        };
      } else if (variant.variationName.toLowerCase() === 'color') {
        buttonStyle = {
          width: '37px', height: '38px', backgroundColor: `${variationValue}`, border: `solid 3px #${variationValue}`, "&:hover": {
            backgroundColor: `${variationValue}`, border: `solid 3px #${variationValue}`,
          },
        };
        buttonVariant = 'contained';
      } else {
        buttonStyle = {
          width: 'auto', padding: '13.5px 19.5px 14.5px', backgroundColor: checkVariationSelected(variationValue) === true ? primaryColor : '#fff', "&:hover": {
            backgroundColor: checkVariationSelected(variationValue) === true ? primaryColor : '#fff',
            border: checkVariationSelected(variationValue) === true ? 'none' : `solid 1px ${bodyColor}`,
          }
        };
      }

      return (
        <Tooltip
          sx={{ color: '#fff', backgroundColor: primaryColor }}
          placement="bottom"
          arrow
          key={key}
          title={isOutOfStock(variations, variant.variationName, variationValue) || isSoldOut(product?.pricing) ? 'This variation is out of stock' : ''}
        >
          <span>
            <Button
              disabled={isSoldOut(product?.pricing) || isOutOfStock(variations, variant?.variationName, variationValue)}
              onClick={() => handleVariations(variant?.variationName, variationValue)}
              variant={buttonVariant}

              sx={{
                ...buttonStyle,
                fontWeight: 'normal',
                height: '48px',
                margin: '10.5px 10px 10px 0px',
                borderRadius: '5px',
                color: checkVariationSelected(variationValue) === true ? '#fff' : bodyColor,
                textTransform: 'capitalize',
                fontSize: '14px',
                border: checkVariationSelected(variationValue) === true ? 'none' : `solid 1px ${bodyColor}`,
                textDecoration: isSoldOut(product?.pricing) || isOutOfStock(variations, variant?.variationName, variationValue) ? 'line-through' : 'none'
              }}
            >
              {variant.variationName.toLowerCase() === 'color' ? (
                checkVariationSelected(variationValue) === true && <CheckBox sx={{ color: '#fff', display: 'block' }} />
              ) : (
                toSentenceCase(variationValue)
              )}
            </Button>
          </span>

        </Tooltip>
      );
    });
  };


  return (
    <Grid container columnSpacing={12} >
      {loading && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box sx={{ display: 'flex', width: '100%', textAlign: 'center', alignContent: 'center', verticalAlign: 'middle', maxWidth: '774px', margin: '10% 50%' }}>
          <CircularProgress />
        </Box>
      </Grid>}
      <Grid item xs={12} style={{ position: 'fixed', zIndex: 20, width: '100%' }}>
        <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
        <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
      </Grid>
      {!loading && <Grid item xs={12} sm={12} md={4} >
        <Box sx={{ padding: '5%', width: "100%", display: 'flex', maxHeight: "400px", maxWidth: { xs: '300px', sm: '700px', md: '400px' }, borderRadius: "15px", transition: 'transform 0.3s ease, opacity 0.3s ease', '&:hover': { transform: 'scale(1.01)', opacity: 0.9 }, boxShadow: '0 5px 30px 0 rgba(0, 0, 0, 0.05)', backgroundColor: '#fff', m: '50px 0 0 0', position: 'relative', overflow: 'hidden' }}>
          <Box sx={{ position: 'relative' }}>
            <img src={activeImage ?? noImagePlaceholder} alt='' style={{ width: '100%', maxHeight: '400px', height: "100%", objectFit: 'contain' }} />
          </Box>
          {isPreOrderEnabled && <Box sx={preOrderTypes[template?.preOrderBadgeType]}>
            <Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#fff', textWrap: 'wrap', fontSize: '12px' }}>Pre-Order Now</Typography>
          </Box>}
          {!checkPreorder(product?.pricing) && isSoldOut(product?.pricing) && <Box sx={preOrderTypes['outOfStock']}>
            <Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#fff', textWrap: 'wrap', fontSize: '12px' }}>Sold Out</Typography>
          </Box>}

        </Box>
        <Grid container spacing={2} sx={{ width: '100%', maxWidth: { xs: '100%', md: '400px' }, mb: '20%', }}>
          {product?.additionalImages?.map((itemImage) => (
            <Grid item xs={3} sm={3} md={6} sx={{ mt: '40px', '&:hover': { transform: 'scale(1.05)' }, maxHeight: { xs: '100px', sm: '100px', md: '300px' }, maxWidth: { xs: '100px', sm: '300px', md: '300px' } }}>
              <Box sx={{ border: itemImage?.image === activeImage ? `solid 1px ${primaryColor}` : 'none', margin: '10px 5px 4px 2px', padding: '10%', borderRadius: '5px', boxShadow: '0 5px 30px 0 rgba(0, 0, 0, 0.05)', backgroundColor: '#fff' }}>
                <img src={itemImage?.image} alt='' style={{ width: '100%', height: '100%', objectFit: 'contain', cursor: 'pointer', transition: 'transform 0.3s ease, opacity 0.3s ease' }} onClick={() => setActiveImage(itemImage?.image)} onMouseEnter={() => setActiveImage(itemImage?.image)} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>}
      {!loading && <Grid item xs={12} sm={12} md={8} >
        <Typography sx={{ margin: { lg: '24px 436px 18px 50px' }, fontSize: '16px', fontWeight: 'bold', textAlign: 'left', color: headingsColor }}>
          {product?.productName}
        </Typography>
        <Typography sx={{ margin: { lg: '18px 807px 10px 50px' }, fontSize: '14px', textAlign: 'left', color: headingsColor }}>
          Description
        </Typography>
        <Typography sx={{ margin: { lg: '10px 203px 10px 50px' }, fontSize: '14px', textAlign: 'left', color: bodyColor }}>
          {product?.productDescription}
        </Typography>
        {product?.variations?.map((variant) => (
          <Box sx={{ marginLeft: { lg: '50px' }, mr: '1px' }} key={variant.variationName}>
            {variant?.variationName !== 'default' && (
              <Typography
                sx={{
                  margin: { lg: '10px 133px 10px 0px' },
                  fontSize: '16px',
                  textAlign: 'left',
                  color: headingsColor,
                  fontWeight: 'bold',
                }}
              >
                <sup style={{ color: '#ef0b0b' }}>*</sup>  Select {toSentenceCase(variant?.variationName)}
              </Typography>
            )}
            <VariationButtons
              variant={variant}
              variations={variations}
              bodyColor={bodyColor}
              primaryColor={primaryColor}
              handleVariations={handleVariations}
              checkVariationSelected={checkVariationSelected}
              toSentenceCase={toSentenceCase}
              isOutOfStock={isOutOfStock}
            />
            {variationsError && <FormHelperText sx={{ color: '#ef0b0b' }}>Please select all variations!</FormHelperText>}
          </Box>
        ))}
        <Box sx={{ padding: '1% 0', mt: '1%' }}>
          {product?.additionalServices?.map((service) => (
            <Box key={service.serviceId} className="filter-brand" sx={{ marginLeft: { lg: '50px' } }}>
              <Typography variant="h6" sx={{ fontSize: '14px', textAlign: 'left', color: headingsColor, fontWeight: 'normal' }}>
                {toSentenceCase(service.serviceName)}
              </Typography>
              {service?.selectionType === 'multiple' ? (
                <Box sx={{ display: "flex", marginTop: "10px", flexDirection: "column", justifyContent: 'start' }}>
                  {(showAllServices[service.serviceId] ? service?.items : service?.items.slice(0, 2)).map((item) => (
                    <Box key={item.itemName} sx={{ display: 'flex' }}>
                      <Checkbox sx={{ color: bodyColor, '&.Mui-checked': { color: primaryColor }, backgroundColor: '#fff' }}
                        checked={checkedItems[item.itemName] || false}
                        value={item.itemName}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setCheckedItems(prevState => ({
                            ...prevState,
                            [item.itemName]: isChecked,
                          }));
                          handleSelectAdditionalServicesMultiple(service, item, isChecked);
                          if (isChecked) {
                            handleCustomizeClick(service?.serviceId, item?._id, item?.itemName);
                          }
                        }}
                      />
                      <Box sx={{ display: 'flex' }}>
                        <Typography sx={{ color: headingsColor, fontSize: "14px", fontWeight: "normal", margin: '10px 0 0px 0px' }}>{item.itemName}</Typography>
                        <Typography sx={{ fontWeight: 600, color: headingsColor, margin: '10px 0 0px 0px' }}>+ Ksh {item.itemPrice}</Typography>
                        <TextField
                          error={false}
                          id={`custom-value-${item._id}`}
                          size="small"
                          sx={{
                            width: '100%', maxWidth: '150px', margin: '2.5px 10px', borderRadius: '5px', backgroundColor: '#fff', display: checkedItems[item.itemName] === true ? 'block' : 'none', borderColor: bodyColor, color: bodyColor,
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: bodyColor,
                              },
                              '&:hover fieldset': {
                                borderColor: bodyColor,
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: bodyColor,
                              },
                            },
                            '& .MuiInputBase-input': {
                              color: bodyColor,
                            },
                            '& .MuiInputBase-input::placeholder': {
                              color: bodyColor,
                              opacity: 1,
                            },
                          }}
                          placeholder={'Enter ' + item.itemName}
                          onChange={() => handleCustomizeClick(service?.serviceId, item?._id, item?.itemName)}
                        />
                      </Box>
                    </Box>
                  ))}
                  {service?.items?.length > 2 && <Box onClick={() => handleToggleShowAllItems(service.serviceId)} sx={{ color: bodyColor, fontSize: '14px', textTransform: 'capitalize', textAlign: 'center', m: '0 20px 20px 10px' }}>
                    {showAllServices[service.serviceId] ? (
                      <Box sx={{ display: 'flex' }}>
                        Show Less <KeyboardArrowUp sx={{ width: '100%', maxWidth: '20px', height: '100%', maxHeight: '20px', margin: '1px 0 1px 5px' }} />
                      </Box>
                    ) : (
                      <Box sx={{ display: 'flex' }}>
                        Show More <KeyboardArrowDown sx={{ width: '100%', maxWidth: '20px', height: '100%', maxHeight: '20px', margin: '1px 0 1px 5px' }} />
                      </Box>
                    )}
                  </Box>}
                </Box>
              ) : (
                <Box style={{ display: "flex", marginTop: "10px", flexDirection: "column", justifyContent: 'start' }}>
                  {(showAllServices[service.serviceId] ? service?.items : service?.items.slice(0, 2)).map((item) => (
                    <Box sx={{ display: 'flex', ml: '10px' }} key={item._id}>
                      <FormControlLabel
                        value={item.itemName}
                        control={
                          <Radio
                            inputProps={{ style: { color: primaryColor } }}
                            sx={{ color: bodyColor, '&.Mui-checked': { color: primaryColor } }}
                            checked={selectedAdditionalServices.some(selectedService =>
                              selectedService.serviceId === service.serviceId &&
                              selectedService.items.some(selectedItem => selectedItem.itemId === item._id)
                            )}
                            onChange={() => handleSelectAdditionalServicesSingle(service, item)}
                          />
                        }
                        sx={{ textWrap: 'nowrap', objectFit: 'contain', color: primaryColor, fontSize: '16px', }}
                        label={
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ color: headingsColor, fontSize: "16px", fontWeight: "normal", margin: '0 10px 0 0' }}>{item.itemName}</Typography>
                            <Typography sx={{ fontWeight: 600, color: headingsColor }}>+ Ksh {item.itemPrice}</Typography>
                          </Box>
                        }
                      />
                    </Box>
                  ))}
                  {service?.items?.length > 2 && (
                    <Box onClick={() => handleToggleShowAllItems(service.serviceId)} sx={{ color: bodyColor, fontSize: '14px', textTransform: 'capitalize', textAlign: 'center', m: '0 20px 20px 0px' }}>
                      {showAllServices[service.serviceId] ? (
                        <Box sx={{ display: 'flex' }}>
                          Show Less <KeyboardArrowUp sx={{ width: '100%', maxWidth: '20px', height: '100%', maxHeight: '20px', margin: '1px 0 1px 5px' }} />
                        </Box>
                      ) : (
                        <Box sx={{ display: 'flex' }}>
                          Show More <KeyboardArrowDown sx={{ width: '100%', maxWidth: '20px', height: '100%', maxHeight: '20px', margin: '1px 0 1px 5px' }} />
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>

              )}
            </Box>
          ))}
        </Box>
        <Divider sx={{ width: '100%', maxWidth: '509px', height: '1px', margin: { lg: '29.5px 38px 29.5px 50px' } }} />
        {
          isPreOrderEnabled && <Box>
            <Typography sx={{ margin: { md: '19.5px 70px 20px 51px' }, fontSize: '14px', fontWeight: 600, color: headingsColor }}>Payment Mode</Typography>
            <RadioGroup column name={0} value={''} >
              <Box sx={{ display: 'flex', ml: { md: '50px' } }} key={1}>
                <FormControlLabel
                  value={0}
                  control={
                    <Radio
                      inputProps={{ style: { color: primaryColor } }}
                      sx={{ color: bodyColor, '&.Mui-checked': { color: primaryColor } }}
                      checked={true}
                      onClick={() => {

                      }}
                    />
                  }
                  sx={{ textWrap: 'nowrap', objectFit: 'contain', color: primaryColor, fontSize: '16px', }}
                />
                <Typography sx={{ color: headingsColor, fontSize: "16px", fontWeight: "normal", margin: '10px 0 0px 0px' }}>{paymentMode === 'onPlaceOrder' ? 'On Placing Order' : 'On Delivery'}</Typography>
              </Box>
            </RadioGroup>
            <Divider sx={{ width: '100%', maxWidth: '509px', height: '1px', margin: { lg: '29.5px 38px 29.5px 50px' } }} />
          </Box>
        }
        <Box sx={{ display: 'flex', margin: { lg: '0px 50px' } }}>
          <Typography sx={{ margin: '0 11px 0 0', fontSize: '14px', fontWeight: 550, lineHeight: 2.57, textAlign: 'left', color: headingsColor }}>SKU:</Typography>
          <Typography sx={{ margin: '0 11px 0 0', fontSize: '14px', fontWeight: 'normal', lineHeight: 2.57, textAlign: 'left', color: bodyColor }}>{product?.productCode}</Typography>
        </Box>
        <Box sx={{ display: 'flex', margin: { lg: '0px 50px' } }}>
          <Typography sx={{ margin: '0 11px 0 0', fontSize: '14px', fontWeight: 550, lineHeight: 2.57, textAlign: 'left', color: headingsColor }}>Category:</Typography>
          <Typography sx={{ margin: '0 11px 0 0', fontSize: '14px', fontWeight: 'normal', lineHeight: 2.57, textAlign: 'left', color: bodyColor }}>{product?.productCategory}</Typography>
        </Box>
        <Box sx={{ display: 'flex', margin: { lg: '0px 50px' } }}>
          <Typography sx={{ margin: '0 11px 0 0', fontSize: '14px', fontWeight: 550, lineHeight: 2.57, textAlign: 'left', color: headingsColor }}>Tags:</Typography>
          <Typography sx={{ margin: '0 11px 0 0', fontSize: '14px', fontWeight: 'normal', lineHeight: 2.57, textAlign: 'left', color: bodyColor }}>{product?.tags?.map(tag => tag.tagName).join(', ')}</Typography>
        </Box>
        <Box sx={{ display: 'flex', margin: { lg: '0px 50px' } }}>
          <Typography sx={{ margin: '0 11px 0 0', fontSize: '14px', fontWeight: 550, lineHeight: 2.57, textAlign: 'left', color: headingsColor }}>Availabity:</Typography>
          <Typography sx={{ margin: '0 11px 0 0', fontSize: '14px', fontWeight: 'normal', lineHeight: 2.57, textAlign: 'left', color: primaryColor }}>{isPreOrderEnabled ? 'Pre-Order' : (stock > 0 ? 'In Stock' : 'Out of Stock')} </Typography>
        </Box>
        {
          isPreOrderEnabled && <Box sx={{ display: 'flex', margin: { lg: '0px 50px' }, alignItems: 'center' }}>
            <Typography sx={{ margin: '0 11px 0 0', fontSize: '14px', fontWeight: 550, lineHeight: 2.57, textAlign: 'left', color: headingsColor }}>Time Left:</Typography>
            <Typography sx={{ margin: '0 11px 0 0', fontSize: '14px', fontWeight: 'normal', lineHeight: 2.57, textAlign: 'left', color: primaryColor }}><CountdownTimer targetDate={endDate} /> </Typography>
          </Box>
        }
        <Box sx={{ display: 'flex', margin: { lg: '0px 50px' } }}>
          <Typography sx={{ margin: '0 18px 0 0', fontSize: '20px', fontWeight: 'bold', lineHeight: 2.57, textAlign: 'left', color: headingsColor }}>Ksh {price + additionalServicesAmount} </Typography>
          {product && product?.pricing?.length > 0 && normalPrice > discountedPrice && <Typography sx={{ margin: '15px 0 2px 1px', fontSize: '16px', fontWeight: 'normal', lineHeight: 'normal', textAlign: 'left', color: '#ef0b0b', textDecoration: 'line-through' }}>Ksh {product?.pricing && product?.pricing.length > 0 ? normalPrice + additionalServicesAmount : product?.productPrice}</Typography>}
          {product && product?.pricing?.length > 0 && normalPrice > discountedPrice && <Typography sx={{ margin: '20px 0 5px 17px', fontSize: '12px', fontWeight: 'normal', lineHeight: 'normal', textAlign: 'left', color: primaryColor }}>(Save Ksh {product?.pricing && product?.pricing.length > 0 ? (normalPrice - discountedPrice) : 0})</Typography>}
        </Box>
        <Grid container rowSpacing={2} columnSpacing={0} sx={{ margin: { lg: '0px 50px' }, alignItems: 'center' }}>
          <Grid item xs={12} sm={12} md={4} sx={{ display: 'flex' }}>
            <Typography sx={{ margin: '10px 0px 0px 0', padding: '8px 5px 7px 1px', borderRadius: '3px', backgroundColor: '#fff' }}>
              <RemoveCircle onClick={() => { handleDecrementCart() }} sx={{ color: bodyColor, width: '30px', height: '30px', objectFit: 'contain' }} />
            </Typography>
            <TextField onChange={(e) => handleCartInput(e.target.value)} disabled={stock <= 0} value={count} inputProps={{
              style: {
                textAlign: 'center', color: bodyColor, fontSize: '14px', height: '15px',
              }
            }} sx={{
              width: '56px', height: '15px', margin: '0px 10px 0px 10px', padding: '9.5px 6.5px 4.5px 4.5px', borderRadius: '5px', alignItems: 'center', textAlign: 'center', '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: bodyColor
                },
                '&:hover fieldset': {
                  borderColor: bodyColor,
                },
                '&.Mui-focused fieldset': {
                  borderColor: bodyColor,
                },
              },
              '& .MuiInputBase-input': {
                color: bodyColor,
              },
              '& .MuiInputBase-input::placeholder': {
                color: bodyColor,
                opacity: 1,
              },
            }} variant='outlined' />
            <Typography sx={{ margin: '10px 0px 0px 0', padding: '8px 5px 7px 5px', borderRadius: '3px', backgroundColor: '#fff' }}>
              <AddCircle disabled={true} onClick={() => { handleIncrementCart(product?.pricing) }} sx={{ color: primaryColor, width: '30px', height: '30px', objectFit: 'contain' }} />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} sx={{ display: 'flex' }}>
            <Button disabled={count < 1 || variations.length < 1 || stock < 1} variant='contained' sx={{ width: '100%', maxWidth: '191px', height: '50px', margin: '10px 0px 0 1px', padding: '13px 18px 12px 10px', opacity: count < 1 || variations.length < 1 || stock < 1 ? 0.45 : 1, borderRadius: '3px', backgroundColor: primaryColor, border: 'none', "&.Mui-disabled": { background: primaryColor, }, '&:hover': { backgroundColor: count < 1 || variations.length < 1 || stock < 1 ? 'rgba(2, 179, 173, 0.45)' : primaryColor, border: 'none' } }} onClick={handleAddToCart}>
              <ShoppingCart sx={{ color: '#fff', fontWeight: 'bold', opacity: 0.9 }} />
              <Typography sx={{ margin: ' 0 0 0 10px', fontSize: '14px', fontWeight: 500, textAlign: 'left', color: '#fff', textTransform: 'capitalize', textWrap: 'nowrap' }}> {!isPreOrderEnabled ? 'Add to Cart' : 'Pre-Order Now'} </Typography>
            </Button>
            {cart?.length > 0 && <Box onClick={() => navigate('/cart')} sx={{ display: 'flex', width: '100%', minWidth: '150px', maxWidth: '170px', height: '100%', maxHeight: '48px', borderRadius: '4px', border: `solid 1px ${primaryColor}`, alignItems: 'center', margin: '10px 0px 0 8px', }}>
              <IconButton size="large" sx={{ '& .badge-container': { position: 'absolute', top: '5px', right: '0px', width: '24px', height: '24px', borderRadius: '50%', backgroundColor: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff', fontWeight: 'bold', fontSize: '12px', border: `solid 1px ${primaryColor}` } }}>
                <ShoppingCartIcon sx={{ fontSize: '1.7rem', color: primaryColor }} />
                <div className="badge-container">
                  <Typography component="sup" style={{ color: primaryColor, fontWeight: 500, padding: '10px 0px', fontSize: '14px' }}>{cart?.length}</Typography>
                </div>
              </IconButton>
              <Typography sx={{ margin: ' 0 0 0 10px', fontSize: '14px', fontWeight: 500, textAlign: 'left', color: primaryColor, textWrap: 'nowrap' }}>View Cart</Typography>
            </Box>}
          </Grid>
        </Grid>
      </Grid >}
    </Grid >
  )
}

export default ProductPage