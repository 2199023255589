import { useEffect, useState } from "react";
import { SuccessAlert } from "../common/snackbar/success";
import { ErrorAlert } from "../common/snackbar/error";
import { Autocomplete, Box, Button, Divider, FormControlLabel, IconButton, InputAdornment, OutlinedInput, Radio, RadioGroup, styled, Switch, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import BreadCrumbs from "../common/breadcrum";
import { TabContext, TabPanel } from "@mui/lab";
import template1 from '../../images/template-2-img@3x.png';
import template2 from '../../images/template-1-img@3x.png';
import { Add, ArrowBackIosOutlined, Circle, Close, EditOutlined } from "@mui/icons-material";
import zedLogo from '../../images/logo-upload@3x.png';
import imgPlaceholder from '../../images/img-holder-icn.svg';
import { useDispatch, useSelector } from "react-redux";
import { isDeepEqual } from "@mui/x-data-grid/internals";
import { setBanners, setButtons, setColors, setDisclaimer, setLogo, setPreOrderBadgeType, setSocialMedia, setTemplate, setTopBar, setTypography } from "../../features/template";
import generateImageUrl from "../common/generateImageUrl";
import MUIRichTextEditor from "mui-rte";
import { HttpRequest } from "../HttpRequest";
import { setEcommerceUrl } from "../../features/configsSlice";
import useGetTemplates from "../common/templates";
import MuiIconAutocomplete from "../common/MUIIconSelector";

const tabStyles = {
    textTransform: 'capitalize', fontSize: '14px', fontWeight: '500', textAlign: 'left', color: '#707070',
    width: '100%', height: '49.5px', margin: '5px 0 0', padding: '15px 13px 14.5px 20px', backgroundColor: '#fff', border: 'none',
    "&.Mui-selected": { color: '#fff', backgroundColor: '#032541', fontWeight: 600, },
    "&.Mui-focusVisible": { backgroundColor: "none", borderRightColor: '#fff' },
    alignItems: 'start', borderRightColor: '#fff',
    '& .MuiTab-root': {
        border: '2px solid transparent',
    },
}
const tabPanelStyles = {
    width: '100%', minHeight: '552px', maxHeight: 'auto', margin: '30px 0 0', padding: '29px 35px 17.8px', border: 'solid 1px #efefef'
}

const CustomToggleButton = styled(ToggleButton)(({ theme, customStyles }) => ({
    width: customStyles.width || '44px',
    height: customStyles.height || '35px',
    backgroundColor: customStyles.backgroundColor || '#fff',
    borderRadius: customStyles.borderRadius || '5px',
    border: `solid 1px ${customStyles.borderColor || '#efefef'}`,
    padding: '9px 20px',
    margin: '0px 10px',
    color: '#707070',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: 2,
    '&.Mui-selected': {
        border: customStyles.border || 'solid 1px #000',
        color: '#000',
        backgroundColor: customStyles.backgroundColor || '#fff',
        borderRadius: customStyles.borderRadius || '5px',
    },
    '&:hover': {
        backgroundColor: customStyles.backgroundColor || '#fff',
    }
}));
const CustomizeStore = () => {
    const { template, logo, colors, typography, buttons, banners, preOrderBadgeType, disclaimer, topBar, socialMedia } = useSelector((state) => state.template);

    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [links, setLinks] = useState([
        { link: '', label: 'Store Appearance' },
    ]);
    const [value, setValue] = useState(1);
    const [uploadLogo, setUploadLogo] = useState(false);
    const [uploadFavicon, setUploadFavicon] = useState(false);
    const [showBackIcon, setShowBackIcon] = useState(false);
    const [title, setTitle] = useState('Store Appearance');
    const [subTitle, setSubTitle] = useState('Theme Options');
    const [description, setDescription] = useState('Customise the appearance of your online store');
    const [numberOfBanners, setNumberOfBanners] = useState(0);
    const [saveButtonColor, setSaveButtonColor] = useState('rgba(3, 37, 65, 0.2)');
    const [editDisclaimers, setEditDisclaimers] = useState(false);
    const [disclaimerTitle, setDisclaimerTitle] = useState('');
    const [disclaimerDescription, setDisclaimerDescription] = useState('');
    const [disclaimerContent, setDisclaimerContent] = useState('');
    const [disclaimerType, setDisclaimerType] = useState('');
    const { businessName, ecommerceUrlEnabled } = useSelector((store) => store.configs);
    const [storeUrl, setStoreUrl] = useState('');
    const templates = useGetTemplates();

    const dispatch = useDispatch();

    const [fonts, setFonts] = useState([
        {
            label: 'Poppins',
            value: 'Poppins'
        },
        {
            label: 'Arial',
            value: 'Arial'
        },
        {
            label: 'Roboto',
            value: 'Roboto'
        },
        {
            label: 'Open Sans',
            value: 'Open Sans'
        },
        {
            label: 'Montserrat',
            value: 'Montserrat'
        },
        {
            label: 'Lato',
            value: 'Lato'
        },
        {
            label: 'Oswald',
            value: 'Oswald'
        },
        {
            label: 'Raleway',
            value: 'Raleway'
        },
        {
            label: 'Nunito',
            value: 'Nunito'
        },
        {
            label: 'Nunito Sans',
            value: 'Nunito Sans'
        }
    ]);
    const [themeOptions, setThemeOptions] = useState({
        template: template,
        logo: logo,
        colors: colors,
        typography: typography,
        buttons: buttons,
        banners: banners,
        preOrderBadgeType: preOrderBadgeType,
        disclaimer: disclaimer,
        topBar: topBar,
        socialMedia: socialMedia,
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const TabHeader = ({ headerTitle, headerDescription }) => {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ width: '100%', height: '52px', margin: '0 35px 35px 0' }}>
                    <Typography sx={{ fontSize: '22px', fontWeight: 500, lineHeight: 1.5, color: '#000', fontFamily: 'Poppins' }}>{headerTitle}</Typography>
                    <Typography sx={{ fontSize: '14px', lineHeight: 2.36, color: '#707070' }}>{headerDescription}</Typography>
                </Box>
                <Button onClick={() => handleSaveThemeOptions()} sx={{ width: '125px', height: '45px', margin: '4px 0 38px 27px', padding: '12.5px 43.7px 12.5px 45.3px', borderRadius: '4px', backgroundColor: saveButtonColor, color: '#fff', textTransform: 'capitalize', fontSize: '14px', fontWeight: 600, '&:hover': { backgroundColor: saveButtonColor, } }}>Save</Button>
            </Box>
        );
    }
    const handleSetUploadLogo = () => {
        setUploadLogo(true);
        setTitle('Upload Logo');
        setShowBackIcon(true);
        setSubTitle('Upload your Logo');
        setDescription('Have a business logo? Upload it now!.');
    };
    const handleSetUploadFavicon = () => {
        setUploadFavicon(true);
        setTitle('Upload Favicon');
        setShowBackIcon(true);
        setSubTitle('Upload your Favicon');
        setDescription('Customize your store with a favicon.');
    };
    const handleBackClickBackIcon = () => {
        setUploadLogo(false);
        setUploadFavicon(false);
        setTitle('Store Appearance');
        setShowBackIcon(false);
        setSubTitle('Theme Option');
        setDescription('Customise the appearance of your online store.');
    }
    const getWidth = () => {
        switch (numberOfBanners) {
            case 1:
                return '770px';
            case 2:
                return '372px';
            default:
                return '254px';
        }
    };
    const getWidthInside = () => {
        switch (numberOfBanners) {
            case 1:
                return '745px';
            case 2:
                return '350px';
            default:
                return '230px';
        }
    };
    const handleSaveThemeOptions = () => {
        dispatch(setTemplate(themeOptions?.template));
        dispatch(setLogo(themeOptions?.logo));
        dispatch(setColors(themeOptions?.colors));
        dispatch(setTypography(themeOptions?.typography));
        dispatch(setButtons(themeOptions?.buttons));
        dispatch(setBanners(themeOptions?.banners));
        dispatch(setPreOrderBadgeType(themeOptions?.preOrderBadgeType));
        dispatch(setDisclaimer(themeOptions?.disclaimer));
        dispatch(setTopBar(themeOptions?.topBar));
        dispatch(setSocialMedia(themeOptions?.socialMedia));

        HttpRequest({
            method: 'POST',
            url: `/api/v1/ecommerce/updateecommercetemplatesettings`,
            body: { template: themeOptions }
        }).then((data) => {
            if (data?.status === 200) {
                setSuccessShow({ state: true, message: "Changes updated successfully!" });
            } else {
                setErrorShow({ state: true, message: data.response?.data?.message });
            }
        }).catch((error) => {
            setErrorShow({ state: true, message: "Error updating store settings" });
        })
    }
    const handleThemeOptions = (option, value) => {
        setThemeOptions({
            ...themeOptions,
            [option]: value,
        });
    }
    const handleMultipleOptions = (value, field, option) => {
        setThemeOptions(prevState => ({
            ...prevState,
            [option]: {
                ...prevState[option],
                [field]: value,
            }
        }));
    };
    const handleUploadLogo = async (event, type, option) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            try {
                const { imageUrl } = await generateImageUrl(selectedFile);
                handleMultipleOptions(imageUrl, type, option);
            } catch (error) {
                
            }
        } 
    }
    const handleUploadBanners = async (event, index) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            try {
                const { imageUrl } = await generateImageUrl(selectedFile);
                setThemeOptions(prevState => {
                    const newPath = [...prevState.banners.path];
                    newPath[index] = { id: index, url: imageUrl };
                    return {
                        ...prevState,
                        'banners': {
                            ...prevState.banners,
                            path: newPath,
                        }
                    };
                });
            } catch (error) {
               
            }
        } 
    }
    const handleSubmitUploadLogo = () => {
        handleBackClickBackIcon();
        setSuccessShow({ state: true, message: "File uploaded successfully!" });
    }

    const handleEditDisclaimer = (type, content, title, description) => {
        setEditDisclaimers(true);
        setDisclaimerTitle(title);
        setDisclaimerDescription(description);
        setDisclaimerContent(content);
        setDisclaimerType(type);
    }

    const handleSelectTemplate = (selectedTemplate) => {
        handleThemeOptions('template', selectedTemplate);
        if (selectedTemplate !== themeOptions.template) {
            setThemeOptions((prev) => ({
                ...prev,
                ...templates[selectedTemplate],
            }));
        }
    }
    const handleViewTemplate = (template) => {
        const params = new URLSearchParams({
            action: 'demo',
            template: template,
        });

        const fullUrl = `${storeUrl}?${params.toString()}`;
        window.open(fullUrl, '_blank');
    }

    const checkUrlStatus = () => {
        HttpRequest({
            method: 'GET',
            url: `/api/v1/ecommerce/getdomainstatusbymerchant`,
        }).then((data) => {
            if (data?.status === 200) {
                let urlStatus = data?.response?.isDomainUrlSet;
                dispatch(setEcommerceUrl(urlStatus));
                if (urlStatus === true) {
                    setStoreUrl(data?.response?.subDomainUrl);
                }
            } else {
                setErrorShow({ state: true, message: data.response?.data?.message });
            }
        }).catch((error) => {
        })
    }

    const handleShowTopBar = (event) => {
        handleMultipleOptions(event.target.checked, 'showTopBar', 'topBar');
    }

    useEffect(() => {
        getWidth();
        getWidthInside();
    }, [numberOfBanners])


    useEffect(() => {
        const reduxThemeOptions = { template, logo, colors, typography, buttons, banners, preOrderBadgeType, disclaimer };
        if (!isDeepEqual(themeOptions, reduxThemeOptions)) {
            const newColor = '#032541';
            setSaveButtonColor(newColor);
        }
    }, [template, logo, colors, typography, buttons, banners, preOrderBadgeType, disclaimer, themeOptions]);
    useEffect(() => {
        checkUrlStatus();
    }, [])
    return (
        <Box sx={{ marginTop: '4%' }}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                <Box sx={{ flexDirection: "column" }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {showBackIcon && <ArrowBackIosOutlined onClick={handleBackClickBackIcon} sx={{ cursor: 'pointer', width: '30px', height: '40px', margin: '0px 15px 4px 0', objectFit: 'contain' }} />}
                        <Typography variant="h4" sx={{ color: "#032541", fontSize: "28px", fontStyle: "normal", fontWeight: "600", }}>
                            {title}
                        </Typography>
                    </Box>
                    <BreadCrumbs links={links} />
                </Box>
            </Box>
            <Box>
                <Typography sx={{ margin: '0px 15px 5px 0', fontSize: '18px', fontWeight: 'bold', lineHeight: 1.83, color: '#000' }}>{subTitle}</Typography>
                <Typography sx={{ fontSize: '16px', lineHeight: 2.06, color: '#707070' }}>{description}</Typography>
            </Box>
            {uploadLogo ?
                <Box sx={{ display: 'flex' }}>
                    <label htmlFor="featured-image">
                        <input style={{ display: "none" }} id="featured-image" name="featured-image" type="file" onChange={(event) => handleUploadLogo(event, 'logoPath', 'logo')} accept=".png, .jpeg, .jpg" />
                        <Box sx={{ width: '370px', height: '200px', margin: '30px 35px 0 0', padding: '10px', borderRadius: '12px', border: 'solid 1px #e0e0e0', backgroundColor: '#fff' }}>
                            {themeOptions?.logo?.logoPath !== ''
                                ?
                                <img src={themeOptions?.logo?.logoPath} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                :
                                <Box sx={{ width: '350px', height: '180px', padding: '0px 19px 20px 3px', borderRadius: '5px', backgroundColor: '#f5f5f5', alignContent: 'center', textAlign: 'center' }}>
                                    <img src={imgPlaceholder} style={{ width: '70px', height: '70px', objectFit: 'contain' }} />
                                </Box>}
                        </Box>
                    </label>
                    <Box>
                        <Box sx={{ width: '288px', height: '71px', margin: '60px 41px 25px 35px' }}>
                            <Box sx={{ display: 'flex' }}>
                                <Circle sx={{ width: '20px', height: '20px', margin: '6px 10px 15px 0', objectFit: 'contain', color: '#f5f5f5' }} />
                                <Typography sx={{ margin: '0 0 0 10px', fontSize: '15px', lineHeight: 1.67, color: '#707070', textWrap: 'nowrap' }}>Accepted file formats:</Typography>
                                <Typography sx={{ margin: '0 0 0 10px', fontSize: '15px', lineHeight: 1.67, color: '#000', textWrap: 'nowrap' }}>.png or .jpg</Typography>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Circle sx={{ width: '20px', height: '20px', margin: '6px 10px 15px 0', objectFit: 'contain', color: '#f5f5f5' }} />
                                <Typography sx={{ margin: '0 0 0 10px', fontSize: '15px', lineHeight: 1.67, color: '#707070', textWrap: 'nowrap' }}>Recommended size:</Typography>
                                <Typography sx={{ margin: '0 0 0 10px', fontSize: '15px', lineHeight: 1.67, color: '#000', textWrap: 'nowrap' }}>350px by 180px</Typography>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Circle sx={{ width: '20px', height: '20px', margin: '6px 10px 15px 0', objectFit: 'contain', color: '#f5f5f5' }} />
                                <Typography sx={{ margin: '0 0 0 10px', fontSize: '15px', lineHeight: 1.67, color: '#707070', textWrap: 'nowrap' }}>Max. File size:</Typography>
                                <Typography sx={{ margin: '0 0 0 10px', fontSize: '15px', lineHeight: 1.67, color: '#000', textWrap: 'nowrap' }}>1MB</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <label htmlFor="featured-image-button">
                                <input style={{ display: "none" }} id="featured-image-button" name="featured-image" type="file" onChange={(event) => handleUploadLogo(event, 'logoPath', 'logo')} accept=".png, .jpeg, .jpg" />
                                <Button component="span" type="input" variant="outlined" sx={{ width: '140px', height: '45px', margin: '25px 15px 29px 35px', padding: '13px 26px 12px 28px', borderRadius: '4px', border: 'solid 1px #032541', fontSize: '14px', fontWeight: 500, lineHeight: 2, textAlign: 'center', color: '#032541', textTransform: 'capitalize', textWrap: 'nowrap', '&:hover': { border: 'solid 1px #032541', backgroundColor: '#fff' } }}>Browse Files</Button>
                            </label>
                            <Button onClick={handleSubmitUploadLogo} variant="contained" sx={{ width: '140px', height: '45px', margin: '25px 15px 29px 35px', padding: '13px 25px 12px 27px', borderRadius: '5px', border: 'solid 1px ##002543', fontSize: '14px', fontWeight: 500, lineHeight: 2, textAlign: 'center', color: '#fff', textTransform: 'capitalize', textWrap: 'nowrap', backgroundColor: '#032541', opacity: themeOptions?.logo?.logoPath !== '' ? 1 : 0.2 }}>Upload Logo</Button>
                        </Box>
                    </Box>
                </Box>
                : uploadFavicon ?
                    <Box sx={{ display: 'flex' }}>
                        <label htmlFor="favicon-image">
                            <input style={{ display: "none" }} id="favicon-image" name="favicon-image" type="file" onChange={(event) => handleUploadLogo(event, 'faviconPath', 'logo')} accept=".png, .jpeg, .jpg" />
                            <Box sx={{ width: '200px', height: '200px', margin: '30px 35px 0 0', padding: '10px', borderRadius: '12px', border: 'solid 1px #e0e0e0', backgroundColor: '#fff', cursor: 'pointer' }}>
                                {themeOptions?.logo?.faviconPath !== ''
                                    ?
                                    <img src={themeOptions?.logo?.faviconPath} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                    :
                                    <Box sx={{ width: '180px', height: '180px', padding: '5px 7px 15px 11px', borderRadius: '5px', backgroundColor: '#f5f5f5', alignContent: 'center', textAlign: 'center' }}>
                                        <img src={imgPlaceholder} style={{ width: '70px', height: '70px', objectFit: 'contain' }} />
                                        <Typography sx={{ margin: '25px 0 0', fontSize: '14px', fontWeight: 500, lineHeight: 1.29, textAlign: 'center', color: '#707070' }}>Drag and drop your image here </Typography>
                                    </Box>}
                            </Box>
                        </label>
                        <Box>
                            <Box sx={{ width: '288px', height: '71px', margin: '60px 41px 25px 35px' }}>
                                <Box sx={{ display: 'flex' }}>
                                    <Circle sx={{ width: '20px', height: '20px', margin: '6px 10px 15px 0', objectFit: 'contain', color: '#f5f5f5' }} />
                                    <Typography sx={{ margin: '0 0 0 10px', fontSize: '15px', lineHeight: 1.67, color: '#707070', textWrap: 'nowrap' }}>Accepted file formats:</Typography>
                                    <Typography sx={{ margin: '0 0 0 10px', fontSize: '15px', lineHeight: 1.67, color: '#000', textWrap: 'nowrap' }}>.png or .jpg</Typography>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Circle sx={{ width: '20px', height: '20px', margin: '6px 10px 15px 0', objectFit: 'contain', color: '#f5f5f5' }} />
                                    <Typography sx={{ margin: '0 0 0 10px', fontSize: '15px', lineHeight: 1.67, color: '#707070', textWrap: 'nowrap' }}>Recommended size:</Typography>
                                    <Typography sx={{ margin: '0 0 0 10px', fontSize: '15px', lineHeight: 1.67, color: '#000', textWrap: 'nowrap' }}>350px by 180px</Typography>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Circle sx={{ width: '20px', height: '20px', margin: '6px 10px 15px 0', objectFit: 'contain', color: '#f5f5f5' }} />
                                    <Typography sx={{ margin: '0 0 0 10px', fontSize: '15px', lineHeight: 1.67, color: '#707070', textWrap: 'nowrap' }}>Max. File size:</Typography>
                                    <Typography sx={{ margin: '0 0 0 10px', fontSize: '15px', lineHeight: 1.67, color: '#000', textWrap: 'nowrap' }}>1MB</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <label htmlFor="featured-image-button">
                                    <input style={{ display: "none" }} id="featured-image-button" name="featured-image" type="file" onChange={(event) => handleUploadLogo(event, 'faviconPath', 'logo')} accept=".png, .jpeg, .jpg" />
                                    <Button component="span" type="input" variant="outlined" sx={{ width: '140px', height: '45px', margin: '25px 15px 29px 35px', padding: '13px 26px 12px 28px', borderRadius: '4px', border: 'solid 1px #032541', fontSize: '14px', fontWeight: 500, lineHeight: 2, textAlign: 'center', color: '#032541', textTransform: 'capitalize', textWrap: 'nowrap', '&:hover': { border: 'solid 1px #032541', backgroundColor: '#fff' } }}>Browse Files</Button>
                                </label>
                                <Button onClick={handleSubmitUploadLogo} variant="contained" sx={{ width: '140px', height: '45px', margin: '25px 15px 29px 35px', padding: '13px 25px 12px 27px', borderRadius: '5px', border: 'solid 1px ##002543', fontSize: '14px', fontWeight: 500, lineHeight: 2, textAlign: 'center', color: '#fff', textTransform: 'capitalize', textWrap: 'nowrap', backgroundColor: '#032541', opacity: themeOptions?.logo?.faviconPath !== '' ? 1 : 0.2 }}>Upload Logo</Button>
                            </Box>
                        </Box>
                    </Box>
                    : <TabContext value={value}>
                        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100%', }}>
                            <Tabs disable orientation="vertical" value={value} onChange={handleChange} aria-label="Store Appearance Tabs" sx={{ borderRight: 1, borderColor: '#fff', width: '265px', height: '496px', margin: '30px 0 126.8px', textAlign: 'left', "& .MuiTabs-indicator": { backgroundColor: "transparent" } }} >
                                <Tab disableRipple sx={tabStyles} label="Template" value={1} />
                                <Tab disableRipple sx={tabStyles} label="Logo & Favicon" value={2} />
                                <Tab disableRipple sx={tabStyles} label="Colors" value={3} />
                                <Tab disableRipple sx={tabStyles} label="Typography" value={4} />
                                <Tab disableRipple sx={tabStyles} label="Buttons" value={5} />
                                <Tab disableRipple sx={tabStyles} label="Top Bar" value={6} />
                                <Tab disableRipple sx={tabStyles} label="Banners" value={7} />
                                <Tab disableRipple sx={tabStyles} label="Pre-Order Badge" value={8} />
                                <Tab disableRipple sx={tabStyles} label="Disclaimer" value={9} />
                            </Tabs>
                            <TabPanel sx={tabPanelStyles} value={1} index={0}>
                                <TabHeader headerTitle='Select a template' headerDescription='Choose a template for your online store.' />
                                <Box sx={{ display: 'flex' }}>
                                    <Box>
                                        <Box onClick={() => handleSelectTemplate('Zedify')} sx={{ cursor: 'pointer', width: '175px', height: '175px', margin: '37px 20px 15px 0', padding: '7px 7px 8px 8px', borderRadius: '14px', border: themeOptions?.template === 'Zedify' ? 'solid 2px #144166' : 'solid 1px #e8e8e8', backgroundColor: '#fff', alignContent: 'center', verticalAlign: 'middle', }}>
                                            <img src={template2} alt="" style={{ width: '100%', height: '100%', borderRadius: '6px', objectFit: 'contain' }} />
                                        </Box>
                                        <Typography sx={{ margin: '15px 82px 0px 63px', fontSize: '16px', fontWeight: 600, lineHeight: 2.06, color: '#000' }}>Zedify</Typography>
                                        {ecommerceUrlEnabled && <Typography onClick={() => handleViewTemplate('Zedify')} sx={{ cursor: 'pointer', margin: '0px 62px 0 53px', fontSize: '12px', fontWeight: 500, lineHeight: 2.75, color: '#707070' }}>View Template</Typography>}
                                    </Box>
                                    <Box>
                                        <Box onClick={() => handleSelectTemplate('Essence')} sx={{ cursor: 'pointer', width: '175px', height: '175px', margin: '37px 20px 15px 0', padding: '7px 7px 8px 8px', borderRadius: '14px', border: themeOptions?.template === 'Essence' ? 'solid 2px #144166' : 'solid 1px #e8e8e8', backgroundColor: '#fff', alignContent: 'center', verticalAlign: 'middle' }}>
                                            <img src={template1} alt="" style={{ width: '100%', height: '100%', borderRadius: '6px', objectFit: 'contain' }} />
                                        </Box>
                                        <Typography sx={{ margin: '15px 82px 0px 63px', fontSize: '16px', fontWeight: 600, lineHeight: 2.06, color: '#000' }}>Essence</Typography>
                                        {ecommerceUrlEnabled && <Typography onClick={() => handleViewTemplate('Essence')} sx={{ cursor: 'pointer', margin: '0px 62px 0 53px', fontSize: '12px', fontWeight: 500, lineHeight: 2.75, color: '#707070' }}>View Template</Typography>}
                                    </Box>
                                </Box>

                            </TabPanel>
                            <TabPanel sx={tabPanelStyles} value={2} index={1}>
                                <TabHeader headerTitle='Logo' headerDescription='Brand your store.' />
                                <Box sx={{ display: 'flex' }}>
                                    <Box>
                                        <RadioGroup column value={themeOptions?.logo?.logoType} onChange={(event) => handleMultipleOptions(event.target.value, 'logoType', 'logo')}>
                                            <Box sx={{ width: '245px', height: '50px', margin: '0px 36px 10px 0', padding: '5px 11px 5px 10px', borderRadius: '6px', border: 'solid 1px #e8e8e8', backgroundColor: '#fff' }}>
                                                <FormControlLabel value="logo" control={<Radio inputProps={{ style: { color: 'red' } }} sx={{ color: '#b2b4bb', '&.Mui-checked': { color: '#032541', } }} />} label={
                                                    <Typography sx={{ color: '#000', fontSize: '12px', fontFamily: 'Poppins', textWrap: 'nowrap', fontWeight: 600, lineHeight: 2.75 }}>
                                                        Use Logo Image
                                                    </Typography>
                                                } sx={{ width: '24px', height: '24px', margin: '15px 0 2px 10px', objectFit: 'contain', color: '#032541', fontSize: '14px', fontFamily: 'Poppins' }} />

                                            </Box>
                                            <Box sx={{ width: '245px', height: '50px', margin: '10px 36px 10px 0', padding: '5px 11px 5px 10px', borderRadius: '6px', border: 'solid 1px #e8e8e8', backgroundColor: '#fff' }}>
                                                <FormControlLabel value="text" control={<Radio inputProps={{ style: { color: 'red' } }} sx={{ color: '#b2b4bb', '&.Mui-checked': { color: '#032541', } }} />} label={
                                                    <Typography sx={{ color: '#707070', fontSize: '12px', fontFamily: 'Poppins', textWrap: 'nowrap', fontWeight: 500, lineHeight: 2.75 }}>
                                                        Use Text
                                                    </Typography>
                                                } sx={{ width: '24px', height: '24px', margin: '15px 0 2px 10px', objectFit: 'contain', color: '#032541', fontSize: '14px', fontFamily: 'Poppins' }} />

                                            </Box>
                                        </RadioGroup>
                                        {themeOptions?.logo?.logoType === 'text' && <Box sx={{ display: 'flex' }}>
                                            <TextField label="Logo Text" value={themeOptions?.logo?.logoText} name="logoText" onChange={(e) => handleMultipleOptions(e.target.value, 'logoText', 'logo')} InputLabelProps={{ sx: { color: '#c2cbd1', lineHeight: '1.57', fontSize: '14px' } }} sx={{ width: "270px", height: '54px', margin: '12px 0 0', borderRadius: '4px' }} />
                                            <TextField label="Slogan" value={themeOptions?.logo?.slogan} name="slogan" onChange={(e) => handleMultipleOptions(e.target.value, 'slogan', 'logo')} InputLabelProps={{ sx: { color: '#c2cbd1', lineHeight: '1.57', fontSize: '14px' } }} sx={{ width: "510px", height: '54px', margin: '12px 0 0 20px', borderRadius: '4px' }} />
                                        </Box>}
                                    </Box>
                                    {themeOptions?.logo?.logoType === 'logo' && <Box sx={{ width: '204px', height: '110px', margin: '25px 0.5px 39.5px 322px', padding: '6px 5px 5px 7px', borderRadius: '12px', border: 'solid 1px #e0e0e0', backgroundColor: 'transparent', display: 'flex', alignItems: 'middle', verticalAlign: 'middle' }}>
                                        <Box sx={{ width: '30px', height: '30px', margin: '36px 0px 10px -27px', padding: '6px 7.1px 7.1px 6px', borderRadius: '5px', backgroundColor: '#032541', justifyContent: 'center', textAlign: 'center' }}>
                                            <EditOutlined onClick={handleSetUploadLogo} sx={{ color: '#fff', width: '22.9px', height: '22.9px', cursor: 'pointer' }} />
                                        </Box>
                                        <img src={themeOptions?.logo?.logoPath !== '' ? themeOptions?.logo?.logoPath : zedLogo} style={{ width: '192px', height: '99px', objectFit: 'contain' }} />
                                    </Box>}

                                </Box>
                                <Divider sx={{ width: '100%', height: '1px', margin: '39.5px 0 24.5px 0.5px', backgroundColor: 'rgba(0, 0, 0, 0.12)' }} />
                                <Box sx={{ width: '100%', height: '52px', margin: '0 35px 35px 0' }}>
                                    <Typography sx={{ fontSize: '22px', fontWeight: 500, lineHeight: 1.5, color: '#000', fontFamily: 'Poppins' }}>Favicon</Typography>
                                    <Typography sx={{ fontSize: '14px', lineHeight: 2.36, color: '#707070' }}>Upload your favicon.</Typography>
                                </Box>
                                <Box>
                                    {themeOptions?.logo?.logoType === 'logo' &&
                                        <Box sx={{ display: 'flex' }}>
                                            <Box onClick={handleSetUploadFavicon} sx={{ width: '70px', height: '70px', margin: '0px 15px 0 1px', padding: '5px', borderRadius: '5px', border: 'solid 1px #e0e0e0', cursor: 'pointer' }}>
                                                {themeOptions?.logo?.faviconPath !== ''
                                                    ?
                                                    <img src={themeOptions?.logo?.faviconPath} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                                    :
                                                    <Box sx={{ width: '50.6px', height: '50.6px', padding: '9.5px 9.1px 9.5px 11.1px', borderRadius: '5px', backgroundColor: '#f5f5f5', alignContent: 'center', textAlign: 'center' }}>
                                                        <img src={imgPlaceholder} style={{ width: '28.2px', height: '28.2px', objectFit: 'contain' }} />
                                                    </Box>}
                                            </Box>
                                            <Box sx={{ margin: '13px 24px 9px 15px' }}>
                                                <Typography sx={{ fontSize: '12px', color: '#707070' }}>
                                                    Recommended Size (500px x 500px) <br />
                                                    PNG or  <br />
                                                    Max. File Size: 1MB
                                                </Typography>
                                            </Box>
                                        </Box>}
                                    {themeOptions?.logo?.logoType === 'text' &&
                                        <Box>
                                            {!themeOptions?.logo?.faviconGenerated && <Button onClick={() => handleMultipleOptions(true, 'faviconGenerated', 'logo')} sx={{ width: '165px', height: '45px', margin: '0px 94px 0 1px', padding: '12.5px 19px 12.5px 20px', borderRadius: '4px', backgroundColor: themeOptions?.logo?.logoText !== '' && themeOptions?.logo?.slogan !== '' ? '#032541' : 'rgba(3, 37, 65, 0.2)', fontSize: '14px', fontWeight: 600, textAlign: 'center', color: '#fff', textTransform: 'capitalize', textWrap: 'nowrap', '&:hover': { backgroundColor: themeOptions?.logo?.logoText !== '' && themeOptions?.logo?.slogan !== '' ? '#032541' : 'rgba(3, 37, 65, 0.2)', } }}>Generate Favicon </Button>}
                                            {themeOptions?.logo?.faviconGenerated && <Box>
                                                <ToggleButtonGroup exclusive value={themeOptions?.logo?.faviconFontFamily} key={'favicons'} style={{ display: "flex", alignItems: "center", }} onChange={(e, value) => handleMultipleOptions(value, 'faviconFontFamily', 'logo')}>
                                                    <CustomToggleButton customStyles={{ width: '70px', border: 'solid 2px #144166', height: '70px', margin: '20px 15px 0 1px', padding: '5px 21px 4px', borderRadius: '14px' }} value={'Poppins'} aria-label={0}>
                                                        <Typography sx={{ fontSize: '44px', fontWeight: 500, lineHeight: 2.68, textAlign: 'center', color: '#032541', fontFamily: 'Poppins' }}>{themeOptions?.logo?.logoText ? themeOptions?.logo?.logoText.charAt(0) : ''}</Typography>
                                                    </CustomToggleButton>
                                                    <CustomToggleButton customStyles={{ width: '70px', border: 'solid 2px #144166', height: '70px', margin: '20px 15px 0 1px', padding: '5px 21px 4px', borderRadius: '14px' }} value={'Inter'} aria-label={0}>
                                                        <Typography sx={{ fontSize: '44px', fontWeight: 500, lineHeight: 2.68, textAlign: 'center', color: '#032541', fontFamily: 'Inter' }}>{themeOptions?.logo?.logoText ? themeOptions?.logo?.logoText.charAt(0) : ''}</Typography>
                                                    </CustomToggleButton>
                                                    <CustomToggleButton customStyles={{ width: '70px', border: 'solid 2px #144166', height: '70px', margin: '20px 15px 0 1px', padding: '5px 21px 4px', borderRadius: '14px' }} value={'TimesNewRoman'} aria-label={0}>
                                                        <Typography sx={{ fontSize: '44px', fontWeight: 'normal', lineHeight: 2.68, textAlign: 'center', color: '#032541', fontFamily: 'TimesNewRoman' }}>{themeOptions?.logo?.logoText ? themeOptions?.logo?.logoText.charAt(0) : ''}</Typography>
                                                    </CustomToggleButton>
                                                    <CustomToggleButton customStyles={{ width: '70px', border: 'solid 2px #144166', height: '70px', margin: '20px 15px 0 1px', padding: '5px 21px 4px', borderRadius: '14px' }} value={'Barlow'} aria-label={0}>
                                                        <Typography sx={{ fontStretch: 'condensed', fontSize: '44px', fontWeight: 800, lineHeight: 2.68, textAlign: 'center', color: '#032541', fontFamily: 'Barlow' }}>{themeOptions?.logo?.logoText ? themeOptions?.logo?.logoText.charAt(0) : ''}</Typography>
                                                    </CustomToggleButton>
                                                </ToggleButtonGroup>
                                            </Box>}
                                        </Box>
                                    }
                                </Box>
                            </TabPanel>
                            <TabPanel sx={tabPanelStyles} value={3} index={2}>
                                <TabHeader headerTitle='Colors' headerDescription='Theme your store with a customised color palette' />
                                <Box sx={{ display: 'flex' }}>
                                    <Box sx={{ width: '385px', height: '50px', margin: '25px 15px 10px 0', padding: '15px', border: 'solid 1px #e8e8e8', backgroundColor: '#fff', borderRadius: '6px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: '12px', fontWeight: 500, lineHeight: 2.75, color: '#707070' }}>Primary Color</Typography>
                                        <input onChange={(event) => handleMultipleOptions(event.target.value, 'primary', 'colors')} type="color" id="primaryColor" name="primaryColor" value={themeOptions?.colors?.primary} style={{ border: 'none', width: '50px', height: '30px', borderRadius: '3px', border: 'none', boxShadow: 'none', backgroundColor: '#fff' }} />
                                    </Box>
                                    <Box sx={{ width: '385px', height: '50px', margin: '25px 15px 10px 0', padding: '15px', border: 'solid 1px #e8e8e8', backgroundColor: '#fff', borderRadius: '6px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: '12px', fontWeight: 500, lineHeight: 2.75, color: '#707070' }}>Secondary Color</Typography>
                                        <input onChange={(event) => handleMultipleOptions(event.target.value, 'secondary', 'colors')} type="color" id="secondaryColor" name="secondaryColor" value={themeOptions?.colors?.secondary} style={{ border: 'none', width: '50px', height: '30px', borderRadius: '3px', border: 'none', boxShadow: 'none', backgroundColor: '#fff' }} />
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Box sx={{ width: '385px', height: '50px', margin: '25px 15px 10px 0', padding: '15px', border: 'solid 1px #e8e8e8', backgroundColor: '#fff', borderRadius: '6px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: '12px', fontWeight: 500, lineHeight: 2.75, color: '#707070' }}>Tertiary Color</Typography>
                                        <input onChange={(event) => handleMultipleOptions(event.target.value, 'tertiary', 'colors')} type="color" id="tertiaryColor" name="tertiaryColor" value={themeOptions?.colors?.tertiary} style={{ border: 'none', width: '50px', height: '30px', borderRadius: '3px', border: 'none', boxShadow: 'none', backgroundColor: '#fff' }} />
                                    </Box>
                                    <Box sx={{ width: '385px', height: '50px', margin: '25px 15px 10px 0', padding: '15px', border: 'solid 1px #e8e8e8', backgroundColor: '#fff', borderRadius: '6px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: '12px', fontWeight: 500, lineHeight: 2.75, color: '#707070' }}>Quaternary Color</Typography>
                                        <input onChange={(event) => handleMultipleOptions(event.target.value, 'quaternary', 'colors')} type="color" id="quaternaryColor" name="quaternaryColor" value={themeOptions?.colors?.quaternary} style={{ border: 'none', width: '50px', height: '30px', borderRadius: '3px', border: 'none', boxShadow: 'none', backgroundColor: '#fff' }} />
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Box sx={{ width: '385px', height: '50px', margin: '25px 15px 10px 0', padding: '15px', border: 'solid 1px #e8e8e8', backgroundColor: '#fff', borderRadius: '6px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: '12px', fontWeight: 500, lineHeight: 2.75, color: '#707070' }}>Headings Text Color</Typography>
                                        <input onChange={(event) => handleMultipleOptions(event.target.value, 'headings', 'colors')} type="color" id="headingsColor" name="headingsColor" value={themeOptions?.colors?.headings} style={{ border: 'none', width: '50px', height: '30px', borderRadius: '3px', border: 'none', boxShadow: 'none', backgroundColor: '#fff' }} />
                                    </Box>
                                    <Box sx={{ width: '385px', height: '50px', margin: '25px 15px 10px 0', padding: '15px', border: 'solid 1px #e8e8e8', backgroundColor: '#fff', borderRadius: '6px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: '12px', fontWeight: 500, lineHeight: 2.75, color: '#707070' }}>Body Text Color</Typography>
                                        <input onChange={(event) => handleMultipleOptions(event.target.value, 'body', 'colors')} type="color" id="bodyColor" name="bodyColor" value={themeOptions?.colors?.body} style={{ border: 'none', width: '50px', height: '30px', borderRadius: '3px', border: 'none', boxShadow: 'none', backgroundColor: '#fff' }} />
                                    </Box>
                                </Box>
                            </TabPanel>
                            <TabPanel sx={tabPanelStyles} value={4} index={3}>
                                <TabHeader headerTitle='Typography' headerDescription='Select custom fonts for your site.' />
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '840px' }}>
                                    <Box sx={{ width: '179px', height: '41.5px', margin: '26px 22px 27.5px 0' }}>
                                        <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#000', margin: '0 12px 4.5px 0' }}>Headings</Typography>
                                        <Typography sx={{ fontSize: '12px', fontWeight: 'normal', color: '#707070', textWrap: 'nowrap', lineHeight: 1.33 }}>Select font to use in headings.</Typography>
                                    </Box>
                                    <Box sx={{ width: '370px', height: '45px' }}>
                                        <Autocomplete
                                            style={{ width: "100%", height: "100%", margin: '25px 0 25px 15px', padding: '12px 15px 13px', borderRadius: '4px', backgroundColor: '#fff' }}
                                            id="outlined-select-meals"
                                            options={fonts}
                                            getOptionLabel={(option) => option.label ?? themeOptions?.typography?.headings}
                                            value={themeOptions?.typography?.headings}
                                            onChange={(event, newValue) => {
                                                handleMultipleOptions(newValue.value, 'headings', 'typography')
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Select Font"
                                                    helperText=""
                                                />
                                            )}
                                            renderOption={(props, option) => (
                                                <Box component="li" {...props} sx={{ margin: '0 27px 5px 0', fontSize: '14px', color: '#707070' }}>
                                                    {option.label}
                                                </Box>
                                            )}
                                        />
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '840px' }}>
                                    <Box sx={{ width: '179px', height: '41.5px', margin: '26px 22px 27.5px 0' }}>
                                        <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#000', margin: '0 12px 4.5px 0' }}>Body</Typography>
                                        <Typography sx={{ fontSize: '12px', fontWeight: 'normal', color: '#707070', textWrap: 'nowrap', lineHeight: 1.33 }}>Select font to use in the rest of the website.</Typography>
                                    </Box>
                                    <Box sx={{ width: '370px', height: '45px' }}>
                                        <Autocomplete
                                            style={{ width: "100%", height: "100%", margin: '25px 0 25px 15px', padding: '12px 15px 13px', borderRadius: '4px', backgroundColor: '#fff' }}
                                            id="outlined-select-meals"
                                            options={fonts}
                                            getOptionLabel={(option) => option.label ?? themeOptions?.typography?.body}
                                            value={themeOptions?.typography?.body}
                                            onChange={(event, newValue) => {
                                                handleMultipleOptions(newValue.value, 'body', 'typography')
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Select Font"
                                                    helperText=""
                                                />
                                            )}
                                            renderOption={(props, option) => (
                                                <Box component="li" {...props} sx={{ margin: '0 27px 5px 0', fontSize: '14px', color: '#707070' }}>
                                                    {option.label}
                                                </Box>
                                            )}
                                        />
                                    </Box>
                                </Box>
                            </TabPanel>
                            <TabPanel sx={tabPanelStyles} value={5} index={4}>
                                <TabHeader headerTitle='Buttons' headerDescription='Customise appearance and borders.' />
                                <Box sx={{}}>
                                    <Box sx={{ width: '385px', height: '50px', margin: '25px 15px 10px 0', padding: '11px 15px 10px', border: 'solid 1px #e8e8e8', backgroundColor: '#fff', borderRadius: '6px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: '12px', fontWeight: 500, lineHeight: 2.75, color: '#707070' }}>Primary Color</Typography>
                                        <input onChange={(event) => handleMultipleOptions(event.target.value, 'primary', 'buttons')} type="color" id="primaryColor" name="primaryColor" value={themeOptions?.buttons?.primary} style={{ border: 'none', width: '50px', height: '30px', borderRadius: '3px', border: 'none', boxShadow: 'none', backgroundColor: '#fff' }} />
                                    </Box>
                                    <Box sx={{ width: '385px', height: '50px', margin: '25px 15px 10px 0', padding: '11px 15px 10px', border: 'solid 1px #e8e8e8', backgroundColor: '#fff', borderRadius: '6px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: '12px', fontWeight: 500, lineHeight: 2.75, color: '#707070' }}>Hover Color</Typography>
                                        <input onChange={(event) => handleMultipleOptions(event.target.value, 'hover', 'buttons')} type="color" id="secondaryColor" name="secondaryColor" value={themeOptions?.buttons?.secondary} style={{ border: 'none', width: '50px', height: '30px', borderRadius: '3px', border: 'none', boxShadow: 'none', backgroundColor: '#fff' }} />
                                    </Box>
                                    <Box sx={{ width: '385px', height: '50px', margin: '25px 15px 10px 0', padding: '11px 15px 10px', border: 'solid 1px #e8e8e8', backgroundColor: '#fff', borderRadius: '6px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: '12px', fontWeight: 500, lineHeight: 2.75, color: '#707070' }}> Border Radius</Typography>
                                        <TextField value={themeOptions?.buttons?.borderRadius} inputProps={{ style: { textAlign: 'center', color: '#000', fontSize: '14px', height: '15px', width: '100%', borderRadius: '5px', margin: '0.5px 10px 0', maxWidth: '16px' } }} variant='outlined' onChange={(event) => handleMultipleOptions(event.target.value, 'borderRadius', 'buttons')} />
                                    </Box>
                                </Box>
                            </TabPanel>
                            <TabPanel sx={tabPanelStyles} value={6} index={5}>
                                <TabHeader headerTitle='Top Bar' headerDescription='Display more information at the top bar.' />
                                <Box>
                                    <Box sx={{ display: 'flex' }}>
                                        <Box sx={{ margin: '0px 28px 24.5px 0' }}>
                                            <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: 500, color: '#000' }}>
                                                Show Top Bar
                                            </Typography>
                                            <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px', lineHeight: 1.33, color: '#707070', margin: '4.5px 0 0' }}>
                                                Display more details and social media.
                                            </Typography>
                                        </Box>
                                        <Switch checked={themeOptions?.topBar?.showTopBar} onChange={handleShowTopBar} inputProps={{ 'aria-label': 'controlled' }} />
                                    </Box>
                                    {themeOptions?.topBar?.showTopBar && <Box>
                                        <Box sx={{ width: '770px', height: '119px', margin: '24.5px 0 15px', padding: '15px 32px 27px 15px', borderRadius: '5px', border: 'solid 1px #efefef', backgroundColor: '#fff' }}>
                                            <Typography sx={{ margin: '0 12px 17px 0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 500, color: '#000' }}>Left Section {themeOptions?.topBar?.leftSectionIcon}</Typography>
                                            <Box sx={{ display: 'flex' }}>
                                                <MuiIconAutocomplete onSelect={(value) => handleMultipleOptions(value, 'leftSectionIcon', 'topBar')} defaultIcon={themeOptions?.topBar?.leftSectionIcon}/>
                                                <TextField label="Left Section Text" value={themeOptions?.topBar?.leftSectionText} name="leftSectionText" onChange={(e) => handleMultipleOptions(e.target.value, 'leftSectionText', 'topBar')} InputLabelProps={{ sx: { color: '#c2cbd1', lineHeight: '1.57', fontSize: '14px' } }} sx={{ width: "510px", height: '54px', margin: '12px 0 0 20px', borderRadius: '4px' }} />
                                            </Box>
                                        </Box>
                                        <Box sx={{ width: '770px', height: '119px', margin: '15px 0', padding: '15px 32px 27px 15px', borderRadius: '5px', border: 'solid 1px #efefef', backgroundColor: '#fff' }}>
                                            <Typography sx={{ margin: '0 12px 17px 0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 500, color: '#000' }}>Middle Section {themeOptions?.topBar?.middleSectionIcon}</Typography>
                                            <Box sx={{ display: 'flex' }}>
                                                <MuiIconAutocomplete onSelect={(value) => handleMultipleOptions(value, 'middleSectionIcon', 'topBar')} defaultIcon={themeOptions?.topBar?.middleSectionIcon}/>
                                                <TextField label="Middle Section Text" value={themeOptions?.topBar?.middleSectionText} name="middleSectionText" onChange={(e) => handleMultipleOptions(e.target.value, 'middleSectionText', 'topBar')} InputLabelProps={{ sx: { color: '#c2cbd1', lineHeight: '1.57', fontSize: '14px' } }} sx={{ width: "510px", height: '54px', margin: '12px 0 0 20px', borderRadius: '4px' }} />
                                            </Box>
                                        </Box>
                                        <Box sx={{ width: '770px', height: '371px', margin: '15px 0 0', padding: '15px 32px 27px 15px', borderRadius: '5px', border: 'solid 1px #efefef', backgroundColor: '#fff' }}>
                                            <Typography sx={{ margin: '0 12px 17px 0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 500, color: '#000' }}>Social Media Section</Typography>
                                            <Box sx={{ display: 'flex', verticalAlign: 'middle' }}>
                                                <Typography sx={{ width: '174px', margin: '25px 24px 20px 25px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 500, color: '#707070' }}>Facebook</Typography>
                                                <OutlinedInput  value={themeOptions?.socialMedia?.facebook} name="facebook" onChange={(e) => handleMultipleOptions(e.target.value, 'facebook', 'socialMedia')} InputLabelProps={{ sx: { color: '#c2cbd1', lineHeight: '1.57', fontSize: '14px' } }} sx={{ width: "510px", height: '54px', margin: '12px 0 0 20px', borderRadius: '4px' }} endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="Clear Input Text"
                                                            onClick={(e) => handleMultipleOptions('', 'facebook', 'socialMedia')}
                                                            onMouseDown={(event) => event.preventDefault()}
                                                            edge="end"
                                                        >
                                                            {themeOptions?.socialMedia?.facebook !== '' ? <Close sx={{ color: '#dc3545' }} /> : ''}
                                                        </IconButton>
                                                    </InputAdornment>
                                                } />
                                            </Box>
                                            <Box sx={{ display: 'flex', verticalAlign: 'middle' }}>
                                                <Typography sx={{ width: '174px', margin: '25px 24px 20px 25px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 500, color: '#707070' }}>Instagram</Typography>
                                                <OutlinedInput value={themeOptions?.socialMedia?.instagram} name="instagram" onChange={(e) => handleMultipleOptions(e.target.value, 'instagram', 'socialMedia')} InputLabelProps={{ sx: { color: '#c2cbd1', lineHeight: '1.57', fontSize: '14px' } }} sx={{ width: "510px", height: '54px', margin: '12px 0 0 20px', borderRadius: '4px' }} endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="Clear Input Text"
                                                            onClick={(e) => handleMultipleOptions('', 'instagram', 'socialMedia')}
                                                            onMouseDown={(event) => event.preventDefault()}
                                                            edge="end"
                                                        >
                                                            {themeOptions?.socialMedia?.instagram !== '' ? <Close sx={{ color: '#dc3545' }} /> : ''}
                                                        </IconButton>
                                                    </InputAdornment>
                                                } />
                                            </Box>
                                            <Box sx={{ display: 'flex', verticalAlign: 'middle' }}>
                                                <Typography sx={{ width: '174px', margin: '25px 24px 20px 25px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 500, color: '#707070' }}>X</Typography>
                                                <OutlinedInput value={themeOptions?.socialMedia?.x} name="x" onChange={(e) => handleMultipleOptions(e.target.value, 'x', 'socialMedia')} InputLabelProps={{ sx: { color: '#c2cbd1', lineHeight: '1.57', fontSize: '14px' } }} sx={{ width: "510px", height: '54px', margin: '12px 0 0 20px', borderRadius: '4px' }} endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="Clear Input Text"
                                                            onClick={(e) => handleMultipleOptions('', 'x', 'socialMedia')}
                                                            onMouseDown={(event) => event.preventDefault()}
                                                            edge="end"
                                                        >
                                                            {themeOptions?.socialMedia?.x !== '' ? <Close sx={{ color: '#dc3545' }} /> : ''}
                                                        </IconButton>
                                                    </InputAdornment>
                                                } />
                                            </Box>
                                            <Box sx={{ display: 'flex', verticalAlign: 'middle' }}>
                                                <Typography sx={{ width: '174px', margin: '25px 24px 20px 25px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 500, color: '#707070' }}>LinkedIn</Typography>
                                                <OutlinedInput value={themeOptions?.socialMedia?.linkedIn} name="linkedIn" onChange={(e) => handleMultipleOptions(e.target.value, 'linkedIn', 'socialMedia')} InputLabelProps={{ sx: { color: '#c2cbd1', lineHeight: '1.57', fontSize: '14px' } }} sx={{ width: "510px", height: '54px', margin: '12px 0 0 20px', borderRadius: '4px' }} endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="Clear Input Text"
                                                            onClick={(e) => handleMultipleOptions('', 'linkedIn', 'socialMedia')}
                                                            onMouseDown={(event) => event.preventDefault()}
                                                            edge="end"
                                                        >
                                                            {themeOptions?.socialMedia?.linkedIn !== '' ? <Close sx={{ color: '#dc3545' }} /> : ''}
                                                        </IconButton>
                                                    </InputAdornment>
                                                } />
                                            </Box>
                                            <Box sx={{ display: 'flex', verticalAlign: 'middle' }}>
                                                <Typography sx={{ width: '174px', margin: '25px 24px 20px 25px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 500, color: '#707070' }}>TikTok</Typography>
                                                <OutlinedInput value={themeOptions?.socialMedia?.tikTok} name="tikTok" onChange={(e) => handleMultipleOptions(e.target.value, 'tikTok', 'socialMedia')} InputLabelProps={{ sx: { color: '#c2cbd1', lineHeight: '1.57', fontSize: '14px' } }} sx={{ width: "510px", height: '54px', margin: '12px 0 0 20px', borderRadius: '4px' }} endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="Clear Input Text"
                                                            onClick={(e) => handleMultipleOptions('', 'tikTok', 'socialMedia')}
                                                            onMouseDown={(event) => event.preventDefault()}
                                                            edge="end"
                                                        >
                                                            {themeOptions?.socialMedia?.tikTok !== '' ? <Close sx={{ color: '#dc3545' }} /> : ''}
                                                        </IconButton>
                                                    </InputAdornment>
                                                } />
                                            </Box>
                                        </Box>
                                    </Box>}
                                </Box>
                            </TabPanel>
                            <TabPanel sx={tabPanelStyles} value={7} index={6}>
                                <TabHeader headerTitle='Banners' headerDescription='Customize banners for your store.' />
                                <Box>
                                    <Typography sx={{ margin: '0px 15px 15px 0', fontSize: '14px', fontWeight: 500, lineHeight: 2.36, color: '#000' }}>Select number of banners</Typography>
                                    <ToggleButtonGroup exclusive value={themeOptions?.banners?.numberOfBanners} key={'banners'} style={{ display: "flex", alignItems: "center", }} onChange={(e, value) => handleMultipleOptions(value, 'numberOfBanners', 'banners')}>
                                        <CustomToggleButton customStyles={{}} value={0} aria-label={0}>
                                            0
                                        </CustomToggleButton>
                                        <CustomToggleButton customStyles={{}} value={1} aria-label={0}>
                                            1
                                        </CustomToggleButton>
                                        <CustomToggleButton customStyles={{}} value={2} aria-label={0}>
                                            2
                                        </CustomToggleButton>
                                        <CustomToggleButton customStyles={{}} value={3} aria-label={0} selected={themeOptions?.banners?.numberOfBanners >= 3}>
                                            3+
                                        </CustomToggleButton>
                                    </ToggleButtonGroup>
                                </Box>
                                {themeOptions?.banners?.numberOfBanners > 0 && <Box>
                                    <Typography sx={{ margin: '30px 51px 15px 2px', fontSize: '14px', fontWeight: 600, color: '#000' }}>Upload your images</Typography>
                                    <Typography sx={{ margin: '0px 310px 0px 0', fontSize: '12px', color: '#707070' }}>Minimum recommended size (1035px x 200px, Ratio 3:2). <br />
                                        Accepted file types: PNG, JPG or WebP</Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {Array.from({ length: themeOptions?.banners?.numberOfBanners }).map((_, index) => (
                                            <label htmlFor={`banner-image-${index}`} key={index}>
                                                <input style={{ display: "none" }} id={`banner-image-${index}`} name={`banner-image-${index}`} type="file" onChange={(event) => handleUploadBanners(event, index)} accept=".png, .jpeg, .jpg" />
                                                <Box sx={{ width: getWidth, height: '155px', margin: '10px 35px 0 0', padding: '9.3px 7.7px 10.7px 9.3px', borderRadius: '12px', border: 'solid 1px #e0e0e0', backgroundColor: '#fff' }}>
                                                    <Box sx={{ width: getWidthInside, height: '145px', padding: '0px 19px 10px 3px', borderRadius: '5px', backgroundColor: '#f5f5f5', alignContent: 'center', textAlign: 'center' }}>
                                                        <img src={themeOptions.banners.path[index]?.url || imgPlaceholder} style={{ width: '70px', height: '70px', objectFit: 'contain' }} alt={`Banner ${index}`} />
                                                    </Box>
                                                </Box>
                                            </label>
                                        ))}
                                        {themeOptions?.banners?.numberOfBanners > 2 && <Box onClick={() => handleMultipleOptions(themeOptions?.banners?.numberOfBanners + 1, 'number', 'banners')} sx={{ width: getWidth, height: '155px', margin: '10px 35px 0 0', padding: '8px 0.2px 15.2px 17px', borderRadius: '8px', border: 'solid 1px #e0e0e0', backgroundColor: '#fff' }}>
                                            <Box sx={{ width: getWidthInside, height: '145px', padding: '0px 19px 10px 3px', borderRadius: '5px', backgroundColor: '#fff', alignContent: 'center', textAlign: 'center' }}>
                                                <Add sx={{ width: '68.8px', height: '68.8px', color: '#e0e0e0' }} />
                                            </Box>
                                        </Box>}
                                    </Box>

                                </Box>}
                            </TabPanel>
                            <TabPanel sx={tabPanelStyles} value={8} index={7}>
                                <TabHeader headerTitle='Pre-Order Badge' headerDescription='Select how the pre-order badge will be displayed.' />
                                <Box sx={{ display: 'flex' }}>
                                    <Box onClick={() => handleThemeOptions('preOrderBadgeType', 'normal')} sx={{ cursor: 'pointer', width: '210px', height: '261px', margin: '35px 35px 0 0', padding: '16px 0 15px 17px', borderRadius: '14px', border: `solid ${themeOptions?.preOrderBadgeType === 'normal' ? '2px #144166' : '1px #e0e0e0'}`, backgroundColor: '#fff', justifyContent: 'center', alignItems: 'center', textAlign: 'center', alignContent: 'center', display: 'flex' }}>
                                        <Box sx={{ width: '176px', height: '230px', margin: '0 17px 0 0', padding: '17px 60.4px 18.1px 60.7px', borderRadius: '8px', backgroundColor: '#f5f5f5', alignContent: 'center', textAlign: 'center', position: 'relative' }}>
                                            <img src={imgPlaceholder} style={{ width: '54.9px', height: '54.9px', objectFit: 'contain' }} />
                                        </Box>
                                        <Box sx={{ width: '113px', height: '30px', margin: '4px 0 196px 80px', borderRadius: '3px', backgroundColor: '#032541', fontSize: '12px', fontWeight: 500, color: '#fff', padding: '10px 11px 0px 12px', position: 'absolute', display: 'flex', justifyContent: 'center' }}>Pre-Order Now</Box>
                                    </Box>
                                    <Box onClick={() => handleThemeOptions('preOrderBadgeType', 'circular')} sx={{ cursor: 'pointer', width: '210px', height: '261px', margin: '35px 35px 0 0', padding: '16px 0 15px 17px', borderRadius: '14px', border: `solid ${themeOptions?.preOrderBadgeType === 'circular' ? '2px #144166' : '1px #e0e0e0'}`, backgroundColor: '#fff', justifyContent: 'center', alignItems: 'center', textAlign: 'center', alignContent: 'center', display: 'flex' }}>
                                        <Box sx={{ width: '176px', height: '230px', margin: '0 17px 0 0', padding: '17px 60.4px 18.1px 60.7px', borderRadius: '8px', backgroundColor: '#f5f5f5', alignContent: 'center', textAlign: 'center', position: 'relative' }}>
                                            <img src={imgPlaceholder} style={{ width: '54.9px', height: '54.9px', objectFit: 'contain' }} />
                                        </Box>
                                        <Box sx={{ width: '64px', height: '64px', margin: '0 122px 174px 0', borderRadius: '3px', color: '#fff', backgroundColor: '#032541', borderRadius: '50%', fontSize: '12px', padding: '2px 4px 2px', alignItems: 'center', justifyContent: 'center', textAlign: 'center', display: 'flex', position: 'absolute' }} >Pre-Order Now </Box>
                                    </Box>
                                    <Box onClick={() => handleThemeOptions('preOrderBadgeType', 'transformed')} sx={{ cursor: 'pointer', width: '210px', height: '261px', margin: '35px 35px 0 0', padding: '16px 0 15px 17px', borderRadius: '14px', border: `solid ${themeOptions?.preOrderBadgeType === 'transformed' ? '2px #144166' : '1px #e0e0e0'}`, backgroundColor: '#fff', justifyContent: 'center', alignItems: 'center', textAlign: 'center', alignContent: 'center', display: 'flex' }}>
                                        <Box sx={{ width: '176px', height: '230px', margin: '0 17px 0 0', padding: '17px 60.4px 18.1px 60.7px', borderRadius: '8px', backgroundColor: '#f5f5f5', alignContent: 'center', textAlign: 'center', position: 'relative' }}>
                                            <img src={imgPlaceholder} style={{ width: '54.9px', height: '54.9px', objectFit: 'contain' }} />
                                        </Box>
                                        <Box sx={{ width: '95px', height: '15px', margin: '58px 101.4px 250.4px 0', borderRadius: '3px', backgroundColor: '#032541', fontSize: '12px', fontWeight: 500, color: '#fff', padding: '5px 36.5px 11.6px 21.7px', position: 'absolute', display: 'flex', justifyContent: 'center', transform: 'rotate(-30deg)', textAlign: 'center', }}>Pre-Order Now</Box>
                                    </Box>
                                </Box>
                            </TabPanel>
                            <TabPanel sx={tabPanelStyles} value={9} index={8}>
                                {editDisclaimers ? <Box>
                                    <ArrowBackIosOutlined onClick={() => setEditDisclaimers(false)} sx={{ cursor: 'pointer', width: '34px', height: '40px', margin: '0 631px 17px 0', objectFit: 'contain' }} />
                                    <TabHeader headerTitle={disclaimerTitle} headerDescription={disclaimerDescription} />
                                    <Box sx={{ width: '840px', height: 'auto', minHeight: '597px', margin: '30px 0 0', padding: '33px 35px 29px', border: 'solid 1px #efefef' }}>
                                        <MUIRichTextEditor label="Edit Disclaimers" defaultValue={themeOptions?.disclaimer[disclaimerType]} onSave={(data) => {
                                            handleMultipleOptions(data, disclaimerType, 'disclaimer'); setSuccessShow({ state: true, message: "Changes saved successfully!" });
                                        }} inlineToolbar={true} />
                                    </Box>
                                </Box>
                                    : <Box>
                                        <TabHeader headerTitle='Disclaimers & Policies' headerDescription='Add disclaimer and policies to your website.' />
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                            <Box sx={{ verticalAlign: 'middle', display: 'flex', width: '370px', height: '108px', margin: '25.5px 30px 20px 0', padding: '2.5px 20px 23.5px 15px', borderRadius: '5px', border: 'solid 1px #efefef', backgroundColor: '#fff' }}>
                                                <Box sx={{ width: '240px', height: '62px', margin: '20px 60px 0 0', }}>
                                                    <Typography sx={{ margin: '0 92px 10px 0', fontSize: '14px', fontWeight: 500, color: '#000' }}>Faqs</Typography>
                                                    <Typography sx={{ margin: '10px 0 0', fontSize: '11px', lineHeight: 1.45, color: '#707070' }}>Provide answers to questions your customers may have.</Typography>
                                                </Box>
                                                <Box>
                                                    {themeOptions?.disclaimer?.faqs === '' && <Add onClick={() => { handleEditDisclaimer('faqs', themeOptions?.disclaimer?.faqs, 'Faqs', 'Provide answers to questions your customers may have.') }} sx={{ cursor: 'pointer', width: '75px', height: '75px', margin: '10px 0 7px 0px', objectFit: 'contain', color: '#e0e0e0' }} />}
                                                    {themeOptions?.disclaimer?.faqs !== '' && <EditOutlined onClick={() => { handleEditDisclaimer('faqs', themeOptions?.disclaimer?.faqs, 'Faqs', 'Provide answers to questions your customers may have.') }} sx={{ cursor: 'pointer', width: '64.3px', height: '64.3px', color: '#707070', margin: '20px 0 7px 0px' }} />}
                                                </Box>
                                            </Box>
                                            <Box sx={{ verticalAlign: 'middle', display: 'flex', width: '370px', height: '108px', margin: '25.5px 30px 20px 0', padding: '2.5px 20px 23.5px 15px', borderRadius: '5px', border: 'solid 1px #efefef', backgroundColor: '#fff' }}>
                                                <Box sx={{ width: '240px', height: '62px', margin: '20px 60px 0 0', }}>
                                                    <Typography sx={{ margin: '0 92px 10px 0', fontSize: '14px', fontWeight: 500, color: '#000' }}>Terms & Conditions</Typography>
                                                    <Typography sx={{ margin: '10px 0 0', fontSize: '11px', lineHeight: 1.45, color: '#707070' }}>Describes the regulations that you attach to your e-commerce business.</Typography>
                                                </Box>
                                                <Box>
                                                    {themeOptions?.disclaimer?.termsAndConditions === '' && <Add onClick={() => { handleEditDisclaimer('termsAndConditions', themeOptions?.disclaimer?.termsAndConditions, 'Terms & Conditions', 'Describes the regulations that you attach to your e-commerce business.') }} sx={{ cursor: 'pointer', width: '75px', height: '75px', margin: '10px 0 7px 0px', objectFit: 'contain', color: '#e0e0e0' }} />}
                                                    {themeOptions?.disclaimer?.termsAndConditions !== '' && <EditOutlined onClick={() => { handleEditDisclaimer('termsAndConditions', themeOptions?.disclaimer?.termsAndConditions, 'Terms & Conditions', 'Describes the regulations that you attach to your e-commerce business.') }} sx={{ cursor: 'pointer', width: '64.3px', height: '64.3px', color: '#707070', margin: '20px 0 7px 0px' }} />}
                                                </Box>
                                            </Box>
                                            <Box sx={{ verticalAlign: 'middle', display: 'flex', width: '370px', height: '108px', margin: '25.5px 30px 20px 0', padding: '2.5px 20px 23.5px 15px', borderRadius: '5px', border: 'solid 1px #efefef', backgroundColor: '#fff' }}>
                                                <Box sx={{ width: '240px', height: '62px', margin: '20px 60px 0 0', }}>
                                                    <Typography sx={{ margin: '0 92px 10px 0', fontSize: '14px', fontWeight: 500, color: '#000' }}>Privacy Policy</Typography>
                                                    <Typography sx={{ margin: '10px 0 0', fontSize: '11px', lineHeight: 1.45, color: '#707070' }}>Describes how customer data is processed, stored and protected.</Typography>
                                                </Box>
                                                <Box>
                                                    {themeOptions?.disclaimer?.privaryPolicy === '' && <Add onClick={() => { handleEditDisclaimer('privaryPolicy', themeOptions?.disclaimer?.privaryPolicy, 'Privacy Policy', 'Describes how customer data is processed, stored and protected.') }} sx={{ width: '75px', height: '75px', margin: '10px 0 7px 0px', objectFit: 'contain', color: '#e0e0e0' }} />}
                                                    {themeOptions?.disclaimer?.privaryPolicy !== '' && <EditOutlined onClick={() => { handleEditDisclaimer('privaryPolicy', themeOptions?.disclaimer?.privaryPolicy, 'Privacy Policy', 'Describes how customer data is processed, stored and protected.') }} sx={{ width: '64.3px', height: '64.3px', color: '#707070', margin: '20px 0 7px 0px' }} />}
                                                </Box>
                                            </Box>
                                            <Box sx={{ verticalAlign: 'middle', display: 'flex', width: '370px', height: '108px', margin: '25.5px 30px 20px 0', padding: '2.5px 20px 23.5px 15px', borderRadius: '5px', border: 'solid 1px #efefef', backgroundColor: '#fff' }}>
                                                <Box sx={{ width: '240px', height: '62px', margin: '20px 60px 0 0', }}>
                                                    <Typography sx={{ margin: '0 92px 10px 0', fontSize: '14px', fontWeight: 500, color: '#000' }}>Shipping Policy</Typography>
                                                    <Typography sx={{ margin: '10px 0 0', fontSize: '11px', lineHeight: 1.45, color: '#707070' }}>Describes how the business will ship customers’ purchases.</Typography>
                                                </Box>
                                                <Box>
                                                    {themeOptions?.disclaimer?.shippingPolicy === '' && <Add onClick={() => { handleEditDisclaimer('shippingPolicy', themeOptions?.disclaimer?.shippingPolicy, 'Shipping Policy', 'Describes how the business will ship customers’ purchases.') }} sx={{ width: '75px', height: '75px', margin: '10px 0 7px 0px', objectFit: 'contain', color: '#e0e0e0' }} />}
                                                    {themeOptions?.disclaimer?.shippingPolicy !== '' && <EditOutlined onClick={() => { handleEditDisclaimer('shippingPolicy', themeOptions?.disclaimer?.shippingPolicy, 'Shipping Policy', 'Describes how the business will ship customers’ purchases.') }} sx={{ width: '64.3px', height: '64.3px', color: '#707070', margin: '20px 0 7px 0px' }} />}
                                                </Box>
                                            </Box>
                                            <Box sx={{ verticalAlign: 'middle', display: 'flex', width: '370px', height: '108px', margin: '25.5px 30px 20px 0', padding: '2.5px 20px 23.5px 15px', borderRadius: '5px', border: 'solid 1px #efefef', backgroundColor: '#fff' }}>
                                                <Box sx={{ width: '240px', height: '62px', margin: '20px 60px 0 0', }}>
                                                    <Typography sx={{ margin: '0 92px 10px 0', fontSize: '14px', fontWeight: 500, color: '#000' }}>Returns and Exchanges</Typography>
                                                    <Typography sx={{ margin: '10px 0 0', fontSize: '11px', lineHeight: 1.45, color: '#707070' }}>Describes procedures for orders eligible for return and exchange.</Typography>
                                                </Box>
                                                <Box>
                                                    {themeOptions?.disclaimer?.returnsAndExchange === '' && <Add onClick={() => { handleEditDisclaimer('returnsAndExchange', themeOptions?.disclaimer?.returnsAndExchange, 'Returns and Exchanges', 'Describes procedures for orders eligible for return and exchange.') }} sx={{ width: '75px', height: '75px', margin: '10px 0 7px 0px', objectFit: 'contain', color: '#e0e0e0' }} />}
                                                    {themeOptions?.disclaimer?.returnsAndExchange !== '' && <EditOutlined onClick={() => { handleEditDisclaimer('returnsAndExchange', themeOptions?.disclaimer?.returnsAndExchange, 'Returns and Exchanges', 'Describes procedures for orders eligible for return and exchange.') }} sx={{ width: '64.3px', height: '64.3px', color: '#707070', margin: '20px 0 7px 0px' }} />}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                }

                            </TabPanel>
                        </Box>
                    </TabContext>}

        </Box>
    );
}
export default CustomizeStore;