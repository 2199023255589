import React, { useEffect, useRef, useState } from 'react';
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Box, CircularProgress, Grid, Typography } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { HttpRequest } from '../HttpRequest';
import imagePlaceholder from '../../images/img-holder-icn.svg';
import Carousel from 'react-multi-carousel';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Link } from 'react-router-dom';
import 'react-multi-carousel/lib/styles.css';
import { ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material';
import Slider from 'react-slick';
import CurrencyFormatter from '../common/currencyFormatter';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import secureLocalStorage from 'react-secure-storage';
import { useSelector } from 'react-redux';
import ProductItem from './productsComponent/productItem';

const productsByCategory = {};

const Products = () => {
    const navigate = useNavigate();
    const categories = Object.keys(productsByCategory);
    const [tokenGenerated, setTokenGenerated] = useState(false);
    const [categoryProducts, setCategoryProducts] = useState([]);
    const [loading, setLoading] = useState(false);
  
    const headingsColor = 'var(--headings)';
    const quaternaryColor = 'var(--quaternary)';
    const tertiaryColor = 'var(--tertiary)';


    const sliderRefs = useRef([]);

    const getProducts = async () => {
        setLoading(true);
        let businessNumber = secureLocalStorage.getItem("businessId");

        HttpRequest({
            method: 'GET',
            url: `/api/v1/ecommerce/grouped_products?businessNumber=${businessNumber}`
        }).then(async (data) => {
            const groupedData = data.response.data.reduce((acc, category) => {
                const products = category?.product?.map((product) => ({
                    ...product,
                }));

                const subcategoryProducts = category?.subcategory && category?.subcategory?.length > 0
                    ? category?.subcategory?.flatMap((subcategory) =>
                        subcategory?.product?.map((product) => ({
                            ...product,
                        }))
                    )
                    : [];

                const categoryProducts = [...products, ...subcategoryProducts];

                if (categoryProducts.length > 0) {
                    acc.push({
                        categoryName: category?.categoryName,
                        products: categoryProducts,
                    });
                }

                return acc;
            }, []);
            setCategoryProducts(groupedData);
        }).catch((error) => {
        }).finally(() => {
            setLoading(false);
        });
    };
    useEffect(() => {
        getProducts();
    }, []);


    const buttonStyle = {
        float: 'right',
        backgroundColor: tertiaryColor,
        color: '#fff',
        borderRadius: '50%',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '20px',
        height: '20px',
        marginRight: '10px',
    };


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {loading && (
                    <Box sx={{ display: 'flex', width: '100%', textAlign: 'center', alignContent: 'center', verticalAlign: 'middle', maxWidth: '774px', margin: '10% 50%' }}>
                        <CircularProgress />
                    </Box>
                )}
            </Grid>
            <Grid item xs={12}>
                {!loading && categoryProducts?.map((categoryProduct, index) => {
                    const productCount = categoryProduct?.products?.length || 0;

                    const settings = {
                        dots: false,
                        infinite: productCount > 1,
                        speed: 500,
                        slidesToShow: productCount < 4 ? productCount : 4,
                        slidesToScroll: productCount < 4 ? productCount : 4,
                        initialSlide: 0,
                        swipeToSlide: true,
                        focusOnSelect: true,
                        centerMode: false,
                        responsive: [
                            {
                                breakpoint: 1920,
                                settings: {
                                    slidesToShow: productCount < 4 ? productCount : 4,
                                    slidesToScroll: productCount < 4 ? productCount : 4,
                                    infinite: productCount > 1,
                                    dots: false,
                                }
                            },
                            {
                                breakpoint: 1280,
                                settings: {
                                    slidesToShow: productCount < 3 ? productCount : 3,
                                    slidesToScroll: productCount < 3 ? productCount : 3,
                                    infinite: productCount > 1,
                                    dots: false,
                                }
                            },
                            {
                                breakpoint: 1024,
                                settings: {
                                    slidesToShow: productCount < 2 ? productCount : 2,
                                    slidesToScroll: productCount < 2 ? productCount : 2,
                                    infinite: productCount > 1,
                                    dots: false,
                                }
                            },
                            {
                                breakpoint: 600,
                                settings: {
                                    slidesToShow: productCount < 2 ? productCount : 2,
                                    slidesToScroll: productCount < 2 ? productCount : 2,
                                    initialSlide: 2
                                }
                            },
                            {
                                breakpoint: 480,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1
                                }
                            }
                        ]
                    };

                    return (
                        <Box sx={{margin:'40px 0px 40px 0px'}}>
                            <Grid container spacing={2} key={categoryProduct.categoryName}>
                                <Grid item xs={12}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', margin: { xs: '0%', sm: '5%', lg: '0px' } }}>
                                        <Typography variant='h2' sx={{ color: headingsColor, fontSize: '16px', fontWeight: 'bold', flexDirection: 'column'}}>{categoryProduct.categoryName}</Typography>
                                        <Box sx={{ fontSize: '14px', fontWeight: '500', textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                                            <Typography sx={{ color: quaternaryColor, cursor: "pointer", fontSize: '14px', fontWeight: 'normal', mr: '15%', textWrap: 'nowrap', verticalAlign: 'middle' }} onClick={() => navigate("/products")}>Show All</Typography>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                <ChevronLeftRounded sx={buttonStyle} onClick={() => sliderRefs.current[index].slickPrev()} />
                                                <ChevronRightRounded sx={buttonStyle} onClick={() => sliderRefs.current[index].slickNext()} />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Slider ref={el => (sliderRefs.current[index] = el)} {...settings}>
                                        {categoryProduct?.products.map((product) => (
                                            <ProductItem product={product} image={product?.thumbailImagePath ?? product?.featuredImage} normalPrice={product.pricing[0].normalPrice}  discountedPrice={product?.pricing[0]?.discountedPrice} productId={product?._id}/>
                                        ))}
                                    </Slider>
                                </Grid>
                            </Grid>
                        </Box>
                    );
                })}
            </Grid>
        </Grid>
    );
};
export default Products;
